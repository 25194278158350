import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { APP_CONSTANTS } from '../config/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  toggleLoader$: Subject<boolean> = new Subject<boolean>();
  dragSelectUpdate$: Subject<boolean> = new Subject<boolean>();
  clientName$: Subject<boolean> = new Subject<boolean>();
  showL3Drawer$: Subject<boolean> = new Subject<boolean>();
  setHeaderName$: Subject<boolean> = new Subject<boolean>();
  refreshHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLogout = false;
  projectListForUser = [];
  keysInStorage = ['roles', 'email', 'roleType', 'projectId', 'clientName', 'timer', 'projectModules', 'projectAdminEmail', 'projectName', 'mf']
  constructor(private router: Router,
    private httpClient: HttpClient,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService) { }

  _setCookie(name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days / (24 * 60 * 60 * 1000)));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + ";samesite=strict; path=/";
  };

  _getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  _deleteCookie(name) {
    this._setCookie(name, "", -1);
  };

  register(data: any): Observable<any> {
    return this.httpClient
      .post(environment.registerUser, data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  login(data: any): Observable<any> {
    return this.httpClient
      .post(environment.login, data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  forgotPassword(data: any): Observable<any> {
    return this.httpClient
      .post(environment.login, data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  revokeUserAccess(data: any): Observable<any> {
    return this.httpClient
      .post(environment.removeUser, data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  createPassword(data: any): Observable<any> {
    return this.httpClient
      .put(environment.createPassword, data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  getUserRolePermission(project_id?: string): Observable<any> {
    let projectId = project_id ? project_id : this.getFromStorage('projectId');
    let data = this.getFromStorage('email')
    return this.httpClient
      .get(environment.getUserRolePermission + '?id=' + data + '&projectId=' + projectId)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  getUserRoleType(): Observable<any> {
    let data = this.getFromStorage('email')
    return this.httpClient
      .get(environment.roletype + '?id=' + data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  getUserProjects(): Observable<any> {
    let email = this.getFromStorage('email')
    return this.httpClient
      .get(environment.getUserProjects + '?id=' + email)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  getAllProjects(): Observable<any> {
    return this.httpClient
      .get(environment.getAllProjects)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  getToken(token) {
    return this._getCookie(token)
  }

  saveToken(tokenDetails) {
    for (const key in tokenDetails) {
      this._setCookie(key,tokenDetails[key],'');
    }
  }

  setInStorage(key,value) {
    localStorage.setItem(key,value);
  }

  getFromStorage(key) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : ''
  }

  removeFromStorage(key) {
    localStorage.removeItem(key);
  }

  clearStorageItems() {
    this._deleteCookie('access_token');
    this._deleteCookie('refresh_token');
    this._deleteCookie('X-XSRF-TOKEN');
    //this._deleteCookie('adal.idtoken');
    this.keysInStorage.forEach(key => {
      this.removeFromStorage(key)
    });
    localStorage.clear();
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  redirectToLogin() {
    this.clearStorageItems();
    setTimeout(() => {
      this.toggleAPILoader(false);
      this.msalService.logoutRedirect();
    },800);
    // if (this.router.url == '/login') {
    //   window.location.reload();
    // }
    // this.router.navigate(['/login']);
  }

  logout() {
    this.toggleAPILoader(true);
    this.httpClient.get(environment.logout).subscribe((result) => {
      this.redirectToLogin();
    }, (err) => {
      this.redirectToLogin();
    });

  }

  showL3Drawer(shouldShow: boolean) {
    this.showL3Drawer$.next(shouldShow);
  }

  setHeaderName(shouldShow: boolean) {
    this.setHeaderName$.next(shouldShow);
  }

  toggleAPILoader(isShown: boolean) {
    this.toggleLoader$.next(isShown);
  }

  generateNewToken(): Observable<any> {
    let data = {
      "access_token": this._getCookie('access_token'),
      "refresh_token": this._getCookie('refresh_token')
    }
    return this.httpClient
      .post(environment.generateToken,data)
      .pipe(map((res: Response) => res),
        catchError(() => of('Error'))
      );
  }

  getClientName() {
    this.clientName$.next(true);
  }

  loginRedirect() {

    if (this.msalGuardConfig.authRequest){
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  getCurrentUserPhoto(token) {
    const req = new HttpRequest('get', 'https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }),
      responseType: 'blob'
    });
    return this.httpClient.request(req);
  }

  public get isAuthenticated(): boolean {
    // return (this.userInfo && this.accessToken) ? true : false;
    console.log('called');
    return this.msalService.instance.getAllAccounts().length > 0 ? true : false;
  }

  processRolesData(data) {
    let roles = data['roles'];
    let roleType = data['roleType'];
    this.setInStorage('roles', JSON.stringify(roles));
    this.setInStorage('roleType', roleType);
  }

  setProjectModules(data) {
    let projectModules = Array.isArray(data['projectModules']) ? data['projectModules'] : [];
    this.setInStorage('projectModules', JSON.stringify(projectModules));
  }

  setLoginAccountDetails() {
    if(this.isAuthenticated) {
      let account = this.msalService.instance.getActiveAccount()
      this.setInStorage('email', account['username']);
      let fullName = this.getNameFromToken(account);
      this.setInStorage('first_name', fullName['fName']);
      this.setInStorage('last_name', fullName['lName']);
    }
  }

  getNameFromToken(account) {
    let firstNameFromProfile = '';
    let lastNameFromProfile = ''
    if (account['name'].indexOf(',') > -1) {
      firstNameFromProfile = account['name'].split(",")[1].trim();
      lastNameFromProfile = account['name'].split(",")[0].trim();
    } else if (account['name'].indexOf('External') > -1) { // external guest user
      firstNameFromProfile = account['name'].split(" ")[0].trim();
    }
    return {fName: firstNameFromProfile, lName:lastNameFromProfile };
  }

}
