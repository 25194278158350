import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/shared/config/app.constants';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor() { }
  routesRolesList = [];

  isSuperAdmin() {
    let roleType = this.getLoggedInUserType();
    let routeRolesData = this.getRolesFromStorage();
    if (routeRolesData.hasOwnProperty('Project') && (roleType === APP_CONSTANTS['superAdmin'])) {
      if (routeRolesData['Project'].indexOf('create') > -1) {
        return true
      }
    }
    return false
  }

  isProjectAdmin() {
    let roleType = this.getLoggedInUserType();
    let routeRolesData = this.getRolesFromStorage();
    if (routeRolesData.hasOwnProperty('Project') && (roleType === APP_CONSTANTS['projectAdmin'])) {
      if (routeRolesData['Project'].indexOf('update') > -1) {
        return true
      }
    }
    return false
  }

  getLoggedInUserType() {
    return localStorage.getItem('roleType') ? localStorage.getItem('roleType') : '';
  }

  getRolesFromStorage() {
    let roles = localStorage.getItem('roles');
    if(roles) {
      const modules = JSON.parse(roles);
      if (modules === null) {
        return []
      }
      return modules[0];
    }
    return []
  }

  loadProjectModulesFromStorage() {
    let projectModules = [];
    let localStorageModules = localStorage.getItem('projectModules');
    let filteredModules = []
    if (localStorageModules) {
      projectModules = JSON.parse(localStorageModules);
      projectModules = projectModules?.sort((a, b) => (a.module_number > b.module_number) ? 1 : ((b.module_number > a.module_number) ? -1 : 0));
      let projectId = localStorage.getItem('projectId');
      for (let i = 0; i < projectModules.length; i++) {
        let obj = {
          routePath: projectModules[i]['module_path'],
          routeName: projectModules[i]['module_title'],
        }
        if (projectId != null && obj['routePath'] == '/project-setup') {
          obj['routePath'] = '/project-setup' + '/' + projectId
        }
        filteredModules.push(obj);

      }
    }
    return filteredModules
  }

  loadRoutesFromStorage() {
    const roleModules = this.getRolesFromStorage();
    this.routesRolesList = [];
    for (const key in roleModules) {
      let roleList;
      let projectId = localStorage.getItem('projectId');
      let routePath = ''
      if (key === 'Project') {
        roleList = this.checkIfHasAccess(key, roleModules[key])
        projectId != null ? routePath = '/project-setup' + '/' + projectId : routePath = '/project-setup';
        let roleRouteDetail = {
          routePath: routePath,
          routeName:'Project Setup',
          roleList : roleList,
          seqId:1
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'L1') {
        roleList = this.checkForProcessScope(roleModules)
        let roleRouteDetail = {
          routePath: '/process-scope',
          routeName: 'Process Scope',
          roleList: roleList,
          seqId: 2
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'Rules Engine') {
        let roleList = this.checkIfHasAccess(key, roleModules[key]);
        let roleRouteDetail1 = {
          routePath: '/rules-engine',
          routeName: 'Rules Engine',
          roleList: roleList,
          seqId: 3
        }
        this.routesRolesList.push(roleRouteDetail1);
      } else if (key === 'AHI') {
        let roleList = this.checkIfHasAccess(key, roleModules[key]);
        let roleRouteDetail = {
          routePath: '/orchestrate',
          routeName: 'Orchestrate',
          roleList: roleList,
          seqId: 4
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'Workshop Planning') {
        roleList = this.checkForWorkshop(roleModules);
        let roleRouteDetail = {
          routePath: '/workshop',
          routeName: 'Workshop',
          subRoutes: ['/workshop/workshop-planning', '/workshop/workshop-execution'],
          roleList: roleList,
          seqId: 5
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'Requirement') {
        let roleList = this.checkIfHasAccess(key, roleModules[key]);
        let roleRouteDetail = {
          routePath: '/requirements',
          routeName: 'Requirements',
          roleList: roleList,
          seqId: 6
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'Ricefw') {
        let roleList = this.checkIfHasAccess(key, roleModules[key]);
        let roleRouteDetail = {
          routePath: '/ricefw',
          routeName: 'RICEFW',
          roleList: roleList,
          seqId: 7
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'Config Scope') {
        let roleList = this.checkIfHasAccess(key, roleModules[key]);
        let roleRouteDetail = {
          routePath: '/configuration-scope',
          routeName: 'Configuration Scope',
          roleList: roleList,
          imgName: 'ricefw',
          seqId: 8
        }
        this.routesRolesList.push(roleRouteDetail);
      } else if (key === 'Client Print L1') {
        roleList = this.checkForClientPrint(roleModules)
        let roleRouteDetail = {
          routePath: '/client-print',
          routeName: 'Client Print',
          roleList: roleList,
          imgName: 'ricefw',
          seqId: 9
        }
        this.routesRolesList.push(roleRouteDetail);
      }
    }
    this.routesRolesList.sort((a, b) => a.seqId - b.seqId);
    let scopeType = localStorage.getItem('scopeType') ? localStorage.getItem('scopeType') : ''
    if(scopeType === 'KE')  {
      let roleRouteDetail = {
        routePath: '/doc-analyzer',
        routeName: 'Document Analyzer',
        roleList: [],
        imgName: '',
        seqId: 10
      }
      this.routesRolesList.push(roleRouteDetail);
    }
    return this.routesRolesList
  }

  getCurrentRoutePermissions(key) {
    for (let index = 0; index < this.routesRolesList.length; index++) {
      const route = this.routesRolesList[index];
      if (route.routeName == key) {
        return route.roleList;
      } else if (key == 'AHI' && route.routeName == 'Orchestrate') {
        return route.roleList;
      }
    }
    return ''
  }

  checkForProcessScope(roleModules) {
    let roleRouteList = {}
    if (roleModules['L1']) {
      let roleList = this.checkIfHasAccess('L1', roleModules['L1'])
      roleRouteList['L1'] = roleList;
    }
    if (roleModules['L2']) {
      let roleList = this.checkIfHasAccess('L2', roleModules['L2'])
      roleRouteList['L2'] = roleList;
    }
    if (roleModules['L3']) {
      let roleList = this.checkIfHasAccess('L3', roleModules['L3'])
      roleRouteList['L3'] = roleList;
    }
    return roleRouteList
  }

  checkForClientPrint(roleModules) {
    let roleRouteList = {}
    if (roleModules['Client Print L1']) {
      let roleList = this.checkIfHasAccess('L1', roleModules['Client Print L1'])
      roleRouteList['L1'] = roleList;
    }
    if (roleModules['Client Print L2']) {
      let roleList = this.checkIfHasAccess('L2', roleModules['Client Print L2'])
      roleRouteList['L2'] = roleList;
    }
    if (roleModules['Client Print L3']) {
      let roleList = this.checkIfHasAccess('L3', roleModules['Client Print L3'])
      roleRouteList['L3'] = roleList;
    }

    if (roleModules['Client Print L4']) {
      let roleList = this.checkIfHasAccess('L4', roleModules['Client Print L4'])
      roleRouteList['L4'] = roleList;
    }
    return roleRouteList
  }

  checkForWorkshop(roleModules) {
    let roleRouteList = {}
    if (roleModules['Workshop Planning']) {
      let roleList = this.checkIfHasAccess('Workshop Planning', roleModules['Workshop Planning'])
      roleRouteList['Workshop Planning'] = roleList;
    }
    if (roleModules['Workshop execution']) {
      let roleList = this.checkIfHasAccess('Workshop execution', roleModules['Workshop execution'])
      roleRouteList['Workshop execution'] = roleList;
    }
    if (roleModules['Workshop Content Approval']) {
      let roleList = this.checkIfHasAccess('Workshop Content Approval', roleModules['Workshop Content Approval'])
      roleRouteList['Workshop Content Approval'] = roleList;
    }
    return roleRouteList
  }

  checkIfHasAccess(key,data) {
    let accessRoles = {
      canRead: false,
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      canApprove: false,
    }
    if(data.indexOf('create') > -1) {
      accessRoles.canCreate = true;
    }
    if(data.indexOf('read') > -1) {
      accessRoles.canRead = true;
    }
    if(data.indexOf('update') > -1) {
      accessRoles.canUpdate = true;
    }
    if(data.indexOf('delete') > -1) {
      accessRoles.canDelete = true;
    }
    if(data.indexOf('approve') > -1) {
      accessRoles.canApprove = true;
    }
    return accessRoles
  }
}
