<header class="bg-white position-fixed mat-elevation-z0">
  <nav class="navbar navbar-expand-lg navbar-light bg-white h-100 pl-0 py-0">
    <!-- <div (click)="onMouseAction()" style="background-color: white;" class="navigation-section">
      <mat-icon *ngIf="!sideNavState" svgIcon="right-nav-btn"></mat-icon>
      <mat-icon *ngIf="sideNavState" svgIcon="left-nav-btn"></mat-icon>
     </div> -->
    <div class="row desktop-header h-100 w-100 mx-0">
       <div class="col align-self-center">
        <!-- <div class="d-inline-flex align-items-center justify-content-start w-100"> -->
          <!-- <div class="d-inline-flex align-items-center w-100"> -->
            <!-- <div class="my-auto header-menu-icon px-2 mr-3 cursor-pointer" (click)="toggleMenu()"><i class="fas fa-bars"></i></div>     -->
            <!-- <a href="/" class="header-logo-container d-inline-flex align-items-center my-auto nav-link px-0 mr-3">
              <div class="header-logo mr-2">
                <img src="./assets/app-logo.png" alt="Kinetic Enterprise">
              </div>
              <div class="my-auto header-logo-text ml-1">
                  <div [ngSwitch]="scopeType">
                    <div *ngSwitchCase="'KCP'">{{pageTitle}}</div>
                    <div *ngSwitchCase="'KFS'">{{pageTitle}}</div>
                    <div *ngSwitchCase="'PSM'">{{pageTitle}}</div>
                    <div *ngSwitchDefault>Kinetic Enterprise</div>
                  </div>
                </div>
            </a>
          </div>
        </div> -->

      </div>
      <div class="col align-self-center">
        <div class="d-inline-flex align-items-center justify-content-end w-100">
          <div class="pr-4 menu-option clientData" *ngIf="showClientData">
            <!-- <div class="d-flex flex-row mr-4">
              <div class="d-flex flex-column mr-5">
                <div class="font-weight-bold">Client</div>
                <div class="font-weight-bold">Project ID:</div>
              </div>
              <div class="d-flex flex-column">
                <div class="text-capitalize">{{clientName}}</div>
                <div class="text-capitalize">{{projectId}}</div>
              </div>
            </div> -->
            <mat-select #select class="text-white" [(value)]="projectId" disableOptionCentering>
              <mat-select-trigger>
                <span class="header-project-name pr-1">{{projectName + ' '}}</span>
                <span class="header-project-name pr-1">|</span>

                <span *ngIf="projectId?.length > 1" class="header-project-id">
                  ID: {{projectId}}
                </span>
              </mat-select-trigger>
              <mat-option>
                <lib-mat-select-search
                  [list]="projectsList"
                  [searchProperties]="[project_name]"
                  (filtered)="filteredProjects = $event"
                  [fixOnTop]="true"
                  [clearSearchInput]="true">
                </lib-mat-select-search>
              </mat-option>
              <mat-option (onSelectionChange)="routeToProject($event,project)" *ngFor="let project of filteredProjects" [value]="project.project_id">
                {{project.project_name}}
              </mat-option>
            </mat-select>
          </div>
          <!-- <div class="pr-4 menu-option">
            <div class="notification-icon"><i class="far fa-bell"></i></div>
          </div> -->
          <div class="pl-4 menu-option">
            <button [matMenuTriggerFor]="menu"  mat-icon-button color="primary" >
                <img class="page-sub-icon" src="/assets/svg/question.svg" alt="Sub Icon">
            </button>
            <mat-menu #menu="matMenu" class="my-menu">
            <div class="menu-container" (click)="$event.stopPropagation()">
              <div class="bg-dark text-white menu-title">
                <span class="ml-2 support-link">Get Support</span>
              </div>
              <div class="d-flex flex-column">
                <div role="button" (click)="openLink(link.url)"  [ngSwitch]="link.label" *ngFor="let link of supportLinks" class="px-3 py-2 d-flex flex-row align-items-center">
                  <img class="page-sub-icon" *ngSwitchCase="'Report an issue'" src="/assets/svg/person-video3-blue.svg" alt="Sub Icon">
                  <img class="page-sub-icon" *ngSwitchCase="'Request any support'" src="/assets/svg/question-blue.svg" alt="Sub Icon">
                  <img class="page-sub-icon" *ngSwitchCase="'User Guide'" src="/assets/svg/arrow-down-circle.svg" alt="Sub Icon">
                  <img class="page-sub-icon" *ngSwitchCase="'Cura'" src="/assets/svg/person-video3-blue.svg" alt="Sub Icon">
                  <img class="page-sub-icon" *ngSwitchDefault src="/assets/svg/person-video3-blue.svg" alt="Sub Icon">
                  <span class="ml-2 item-title">{{link.label}}</span>
                  <div class="px-2 py-2">
                    <img class="page-sub-icon" src="/assets/svg/open-link.svg" alt="Sub Icon">
                  </div>
                </div>
              </div>
            </div>
            </mat-menu>
          </div>
          <div class="pl-4 menu-option">
            <div class="user-setting" *ngIf="profilePic && !userNameText">
              <img  class="profile-image"  [src]="profilePic">
              <img class="user-setting-icon" [matMenuTriggerFor]="menu1" src="/assets/svg/bi_gear-fill.svg" alt="Sub Icon">
            </div>
            <div class="user-setting" *ngIf="!profilePic && userNameText">
              <span  class="profile-image profile-text align-items-center justify-content-center text-white" >{{userNameText}}</span>
              <img class="user-setting-icon" [matMenuTriggerFor]="menu1" src="/assets/svg/bi_gear-fill.svg" alt="Sub Icon">
            </div>
            <mat-menu #menu1="matMenu" class="my-menu">
              <div *ngIf="toggleMenuHeader" class="menu-container" (click)="$event.stopPropagation()">
                <div class="bg-dark text-white menu-title">
                  <span class="ml-2 support-link">{{firstAndLastName}}</span>
                  <br>
                  <span class="ml-2">{{emailId}}({{memberFirm}})</span>
                </div>
                <div class="d-flex flex-column">
                  <div role="button"  (click)="toggleMenuProjectSetting($event)" class="px-3 py-3 d-flex flex-row align-items-center">
                    <img class="page-sub-icon" src="/assets/svg/project-settings.svg" alt="Sub Icon">
                    <span class="ml-2 item-title">Project Settings</span>
                    <!-- <button mat-icon-button color="primary" >
                     <img class="page-sub-icon" src="/assets/svg/open-link.svg" alt="Sub Icon">
                    </button> -->
                  </div>
                  <mat-divider></mat-divider>
                  <div (click)="authService.logout() " role="button" class="px-3 py-3 d-flex flex-row align-items-center">
                    <img class="page-sub-icon" src="/assets/svg/logout-new.svg" alt="Sub Icon">
                    <span class="ml-2 item-title">Logout</span>
                    <!-- <button mat-icon-button color="primary" >
                     <img class="page-sub-icon" src="/assets/svg/open-link.svg" alt="Sub Icon">
                    </button> -->
                  </div>
                </div>
              </div>
              <div *ngIf="!toggleMenuHeader" class="menu-container" (click)="$event.stopPropagation()">
                <!-- <div class="bg-dark text-white menu-title">
                  <span class="ml-2 support-link">Project Settings</span>
                </div> -->
                <div class="d-flex flex-column">
                  <div role="button"  (click)="toggleMenuProjectSetting($event)" class="px-3 py-3 d-flex flex-row align-items-center bg-dark text-white menu-title">
                    <img class="page-sub-icon" src="/assets/svg/back-circle.svg" alt="Sub Icon">
                    <span class="ml-2">Project Settings</span>
                    <!-- <button mat-icon-button color="primary" >
                     <img class="page-sub-icon" src="/assets/svg/open-link.svg" alt="Sub Icon">
                    </button> -->
                  </div>
                  <mat-divider></mat-divider>
                  <div role="button" (click)="confirmRevoke($event)" class="px-3 py-3 d-flex flex-row align-items-center">
                    <img class="page-sub-icon" src="/assets/svg/revoke-access.svg" alt="Sub Icon">
                    <span class="ml-2 item-title">Revoke My Access</span>
                    <!-- <button mat-icon-button color="primary" >
                     <img class="page-sub-icon" src="/assets/svg/open-link.svg" alt="Sub Icon">
                    </button> -->
                  </div>
                  <mat-divider *ngIf="toggleConfirmRevoke"></mat-divider>
                  <div *ngIf="toggleConfirmRevoke" class="d-flex flex-column align-items-center pt-3 pb-2">
                    <span class="mb-2 font-weight-bold">Confirm Revoke?</span>
                    <div class="d-flex">
                      <button mat-raised-button color="warn" class="mr-2" (click)="revokeUserAccess($event)">Yes</button>
                      <button mat-stroked-button (click)="confirmNoRevoke($event)">No</button>
                    </div>
                  </div>
                </div>
              </div>


            </mat-menu>
          </div>

        </div>
      </div>
    </div>
  </nav>
</header>
