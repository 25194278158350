import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  constructor(private _snackBar: MatSnackBar) { }
  showSnackMessage(msg) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 5 * 1000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['white-bg','text-center'],
      data: msg
    });
  }
}
