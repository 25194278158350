<mat-progress-bar mode="buffer" *ngIf="isRouteLoading; Else appContainer">
</mat-progress-bar>
<!-- <div class="spinner-container" *ngIf="isAPIInProgress">
  <mat-progress-spinner
          class="example-margin"
          [color]="'Primary'"
          [mode]="'indeterminate'"
          [diameter]="50"
          [strokeWidth]="5"
          >
  </mat-progress-spinner>
</div> -->

<div class="spinner w-100 h-100 position-fixed" *ngIf="isAPIInProgress">
  <!-- <mat-spinner [diameter]="40" [strokeWidth]="3" class="position-absolute mat-loader"></mat-spinner> -->
  <div class="w-100 h-100 d-flex justify-content-center align-items-center" >
    <object type="image/svg+xml" data="/assets/loader.svg" width="150" height="150"></object>
  </div>
</div>

<ng-template #appContainer>
  <div class="profile-pages h-100" *ngIf="isPublicPage; Else privatePage">
    <router-outlet></router-outlet>
  </div>
  <ng-template #privatePage>
    <div class="app-wrapper">
      <div class="app-inner-wrapper">
        <!-- <app-header (onSideNavToggle)="toggleSideNav($event)"></app-header> -->
        <mat-drawer-container class="app-container h-100" [ngClass]="{
           'adjustConfigScopeDrawer' : isConfigScopeL3Open
         }">
          <mat-drawer [ngClass]="!sideNavState ? 'sideNavOpened':''"
            [@onSideNavChange]="sideNavState ? 'open' : 'close'" mode="side" [opened]="isSideNavShown">
            <div class="d-flex flex-column justify-content-between h-100">
              <section class="menucontent">
                <div class="list-group" (mouseenter)="onMouseHover()" (mouseleave)="onMouseLeave()">
                  <div class="style-header">
                    <div class="side-header">
                      <div class="list-group-item d-flex px-2 mx-auto align-items-center header-logo-container-side header-item" >
                          <span class="nav-img d-flex align-items-center mr-1"><img  src="./assets/svg/Kinetic_Enterprise-RGB-white.svg" alt="Kinetic Enterprise"></span>

                          <div [ngSwitch]="scopeType" [@animateText]="linkText ? 'show' : 'hide'">
                            <span class="header-logo-text-side ml-1" *ngSwitchCase="'KCP'">{{pageTitle}}</span>
                            <span class="header-logo-text-side ml-1" *ngSwitchCase="'KFS'">{{pageTitle}}</span>
                            <span class="header-logo-text-side ml-1" *ngSwitchCase="'PSM'">{{pageTitle}}</span>
                            <span *ngSwitchDefault class="header-logo-text-side ml-1">Kinetic Enterprise</span>
                          </div>
                      </div>
                  </div>
                </div>
                  <div class="">
                    <div class="list-group-item  home-img d-flex align-items-center" [routerLinkActive]="['active']"
                      [routerLink]="['/home']">
                      <span class="nav-img d-flex align-items-center mr-3"><img src="/assets/svg/home.svg"
                          alt="RICEFW Icon" /></span>
                      <span [@animateText]="linkText ? 'show' : 'hide'" class="home">Home</span>
                    </div>
                  </div>
                  <div>
                    <div class="list-group-item p-0 d-flex align-items-center workshop-panel" [routerLinkActive]="['active']" *ngIf="prepareArr.length">
                      <mat-accordion class="w-100">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <img class="workshop-heading__logo-container__icon mr-3" src="/assets/svg/visibility.svg"
                                alt="workshop Icon" />
                              <a class="active-link">
                                Prepare
                              </a>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="workshop-list-items">
                            <div [routerLinkActive]="['active']" class="mb-2 sm-router-ative-link py-2"  *ngFor="let item of prepareArr">
                              <span  class="d-flex link-padding"
                                [routerLink]="item.routePath">
                                <img class="mr-2" src="/assets/svg/{{(item.routeName | lowercase)}}.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span [@animateText]="linkText ? 'show' : 'hide'" class="route-text">{{item.routeName}}</span>
                              </span>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <div>
                    <div class="list-group-item p-0 d-flex align-items-center workshop-panel"
                      [routerLinkActive]="['active']" *ngIf="exploreArr.length">
                      <mat-accordion class="w-100">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <img class="workshop-heading__logo-container__icon mr-3" src="/assets/svg/target.svg"
                                alt="workshop Icon" />
                              <a class="active-link">
                                Imagine
                              </a>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="workshop-list-items">
                            <div class="mb-2 sm-router-ative-link py-2"  [routerLinkActive]="['active']" *ngFor="let item of exploreArr">
                              <span class="d-flex link-padding"
                                *ngIf="item.routePath != '/workshop'" [routerLink]="item.routePath">
                                <img class="mr-2" src="/assets/svg/{{(item.routeName | lowercase)}}.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span [@animateText]="linkText ? 'show' : 'hide'" class="route-text">{{item.routeName}}</span>
                              </span>
                              <span  class="d-flex link-padding"
                                *ngIf="item.routePath == '/workshop'" [routerLink]="['/workshop/workshop-planning']">
                                <img class="mr-2" src="/assets/svg/workshop planning.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span  [@animateText]="linkText ? 'show' : 'hide'" class="route-text">Workshop Planning</span>
                              </span>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <div>
                    <div class="list-group-item p-0 d-flex align-items-center workshop-panel"
                      [routerLinkActive]="['active']" *ngIf="realizeArr.length">
                      <mat-accordion class="w-100">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <img class="workshop-heading__logo-container__icon mr-3" src="/assets/svg/recycle.svg"
                                alt="workshop Icon" />
                              <a class="active-link">
                                Deliver
                              </a>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="workshop-list-items">
                            <div class="mb-2 sm-router-ative-link py-2"  [routerLinkActive]="['active']"  *ngFor="let item of realizeArr">
                              <span class="d-flex link-padding"
                                *ngIf="item.routePath != '/workshop'" [routerLink]="item.routePath">
                                <img class="mr-2" src="/assets/svg/{{(item.routeName | lowercase)}}.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span class="route-text" [@animateText]="linkText ? 'show' : 'hide'">{{item.routeName}}</span>
                              </span>
                              <span class="d-flex link-padding"
                                *ngIf="item.routePath == '/workshop'" [routerLink]="['/workshop/workshop-execution']">
                                <img class="mr-2" src="/assets/svg/workshop execution.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span class="route-text" [@animateText]="linkText ? 'show' : 'hide'">Workshop Execution</span>
                              </span>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <div>
                    <div class="list-group-item p-0 d-flex align-items-center workshop-panel"
                      [routerLinkActive]="['active']" *ngIf="deployArr.length">
                      <mat-accordion class="w-100">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <img class="workshop-heading__logo-container__icon mr-3" src="/assets/svg/check.svg"
                                alt="workshop Icon" />
                              <a class="active-link">
                                Deploy
                              </a>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="workshop-list-items">
                            <div class="mb-2 sm-router-ative-link py-2"  [routerLinkActive]="['active']" *ngFor="let item of deployArr">
                              <span class="d-flex link-padding"
                                *ngIf="item.routePath == '/client-print'" [routerLink]="item.routePath">
                                <img class="mr-2" src="/assets/svg/{{(item.routeName | lowercase)}}.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span class="route-text" [@animateText]="linkText ? 'show' : 'hide'">{{item.routeName}}</span>
                              </span>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <div>
                    <div class="list-group-item p-0 d-flex align-items-center workshop-panel"
                      [routerLinkActive]="['active']" *ngIf="othersArr.length">
                      <mat-accordion class="w-100">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <img class="workshop-heading__logo-container__icon mr-3"
                                src="/assets/svg/configuration-scope.svg" alt="workshop Icon" />
                              <a class="active-link">
                                Others
                              </a>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="workshop-list-items">
                            <div class="mb-2 sm-router-ative-link py-2"  [routerLinkActive]="['active']" *ngFor="let item of othersArr">
                              <span class="d-flex link-padding"
                                [routerLink]="item.routePath">
                                <img class="mr-2" src="/assets/svg/{{(item.routeName | lowercase)}}.svg"
                                  alt="{{item.routeName}} Icon" />
                                <span class="route-text" [@animateText]="linkText ? 'show' : 'hide'">{{item.routeName}}</span>
                              </span>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <!-- <div (click)="authService.logout() " class="list-group-item d-flex align-items-center">
                    <span class="d-flex align-items-center">
                      <img class="mr-3" src="/assets/svg/logout.svg" alt="Logout Icon" />
                      <span [@animateText]="linkText ? 'show' : 'hide'" class="logout-text">Logout</span>
                    </span>
                  </div> -->
                </div>
              </section>
              <section class="menubottomcontent mb-1" (mouseenter)="onMouseHover()" (mouseleave)="onMouseLeave()" [@animateText]="linkText ? 'show' : 'hide'">
                <div class="clientName mb-1">{{clientName}}</div>
                <div class="deloitteName mb-2">Deloitte Consulting LLP</div>
                <div><a class="cursor-pointer"
                    href="https://www2.deloitte.com/us/en/footerlinks1/privacy-notice-limited-controller.html"
                    target="_blank">Privacy
                    Policy</a></div>
                <div><a class="cursor-pointer"
                    href="https://cookienotice.deloitte.com/"
                    target="_blank">Cookies</a></div>
              </section>
            </div>
          </mat-drawer>
          <!-- <app-header></app-header> -->
          <mat-drawer-content [@onMainContentChange]="sideNavState ? 'open': 'close'" (scroll)="onScroll($event)"
            class="setMinHeight bg-grey-color">
            <app-header (onMouseLeave)="onMouseLeave()" (onMouseEnter)="onMouseHover()" [sideNavState]="sideNavState"></app-header>
            <router-outlet></router-outlet>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </div>
  </ng-template>
</ng-template>
