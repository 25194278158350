import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AuthGuard } from './helpers/auth-guard';
const routes: Routes = [
  {
    path: 'login',
    loadChildren:() => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [MsalGuard],
    data: { isPublicRoute: true }
  },
  {
    path: 'register',
    loadChildren:() => import('./pages/register/register.module').then(m => m.RegisterModule),
    data: { isPublicRoute: true }
  },
  {
    path: 'project-setup/:id',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/project-setup/project-setup.module').then(m => m.ProjectSetupModule)
  },
  {
    path: 'project-setup',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/project-setup/project-setup.module').then(m => m.ProjectSetupModule)
  },
  {
    path: 'projects',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/projects-list/projects-list.module').then(m => m.ProjectsListModule)
  },
  {
    path: 'process-scope',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/process-scope/process-scope.module').then(m => m.ProcessScopeModule)
  },
  {
    path: 'forgot-password',
    loadChildren:() => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: { isPublicRoute: true }
  },
  {
    path: 'create-password',
    loadChildren:() => import('./pages/create-password/create-password.module').then(m => m.CreatePasswordModule),
    data: { isPublicRoute: true }
  },
  {
    path: 'home',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'analytics',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule)
  },
  {
    path: 'orchestrate',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/orchestrate/orchestrate.module').then(m => m.OrchestrateModule)
  },
  {
    path: 'rules-engine',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/rules-engine/rules-engine.module').then(m => m.RulesEngineModule)
  },
  {
    path: 'workshop',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/workshop/workshop.module').then(m => m.WorkshopModule)
  },
  {
    path: 'ricefw',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/ricefw/ricefw.module').then(m => m.RicefwModule)
  },
  // {
  //   path: 'configuration-scope',
  //   canActivate: [MsalGuard],
  //   loadChildren: () => import('./pages/configuration-scope/configuration-scope.module').then(m => m.ConfigurationScopeModule)
  // },
  {
    path: 'configuration-scope',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/configuration-automation/configuration-automation.module').then(m => m.ConfigurationAutomationModule)
  },
  {
    path: 'requirements',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/requirements/requirements.module').then(m => m.RequirementsModule)
  },
  {
    path: 'client-print',
    loadChildren: () => import('./pages/client-print/client-print.module').then(m => m.ClientPrintModule)
  },
  {
    path: 'change-log',
    loadChildren: () => import('./pages/change-log/change-log.module').then(m => m.ChangeLogModule)
  },
  {
    path: 'super-admin',
    canActivate: [MsalGuard],
    loadChildren:() => import('./pages/super-admin/super-admin.module').then(m => m.SuperAdminModule),
    data: { isPublicRoute: true }
  },
  {
    path: 'doc-analyzer',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/doc-analyzer/doc-analyzer.module').then(m => m.DocAnalyzerModule)
  },
  {
    path: 'spec-starter',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/fs-ts/fs-ts.module').then(m => m.FSTSModule)
  },
  {
    path: 'search-documents',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/kx-search/kx-search.module').then(m => m.KXSearchModule)
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
