import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { AuthService } from 'src/app/shared/services/auth.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, debounceTime, filter } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { onMainContentChange, onSideNavChange, animateText } from 'src/app/shared/animations/animation';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventType, EventMessage } from "@azure/msal-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange, onSideNavChange, animateText]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Kinetic-Finance';
  isSideNavShown: boolean = true;
  isPublicPage: boolean = false;
  routesList = [];
  projectId = '';
  publicRoutes = ['/login', '/create-password', '/forgot-password', '/super-admin'];
  isRouteLoading: boolean = true;
  isConfigScopeL3Open: boolean = false;
  isAPIInProgress: boolean;
  destroy$ = new Subject();
  routeAccessList = [];
  currentRoute: any;
  clientName = '';
  panelOpenState = false;
  prepareArr = [];
  exploreArr = [];
  realizeArr = [];
  deployArr = [];
  othersArr = [];
  scopeType = '';
  pageTitle = '';
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private roleService: RoleService) { }

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroy$)
      ).subscribe(async (res) => {
        if(!this.authService.isAuthenticated) {
          await this.authService.loginRedirect();
        }
      })

      this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS))
      .subscribe((result: EventMessage) => {
        this.checkAndSetActiveAccount();
      });

    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        let configPath = event.url.includes('configuration-scope?projectName=');
        if(configPath) {
          localStorage.setItem('projectId', event.url.split('=')[1]);
          this.authService.setLoginAccountDetails()
          await this.triggerConfigAPIs();
        }
        this.currentRoute = event;
        this.projectId = localStorage.getItem('projectId');
        this.isRouteLoading = false;
        this.manageMenuItems();
        this.isPublicPage = this.route.root.firstChild.snapshot.data.isPublicRoute;
        this.authService.getClientName();
        if (this.authService.isLogout) {
          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      }
    });
    this.authService.toggleLoader$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isShown: boolean) => {
      setTimeout(() => {
        this.isAPIInProgress = isShown;
      }, 0);
    });
    this.authService.setHeaderName$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isShown: boolean) => {
      setTimeout(() => {
        this.scopeType = localStorage.getItem('scopeType') ? localStorage.getItem('scopeType') : ''
        this.pageTitle = localStorage.getItem('pageTitle') ? localStorage.getItem('pageTitle') : ''
      }, 0);
    });
    this.authService.showL3Drawer$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isShown: boolean) => {
      this.isConfigScopeL3Open = isShown;
    });
    this.authService.clientName$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((val) => {
      setTimeout(() => {
        if (val) {
          this.clientName = localStorage.getItem('clientName') ? localStorage.getItem('clientName') : ''
        }
      }, 0);
    });
  }

  manageMenuItems() {
    this.roleService.loadRoutesFromStorage();
    this.prepareSideMenu();
  }

  prepareSideMenu() {
    this.routeAccessList = this.roleService.loadProjectModulesFromStorage();

    this.prepareArr = this.routeAccessList.filter((route) => { return route.routePath == '/process-scope' || route.routePath.indexOf('/project-setup') > -1 })
    this.exploreArr = this.routeAccessList.filter((route) => {
      return route.routePath == '/rules-engine' || route.routePath == '/orchestrate' || route.routePath == '/workshop'
    })
    this.realizeArr = this.routeAccessList.filter((route) => {
      return route.routePath == '/configuration-scope' || route.routePath == '/ricefw' || route.routePath == '/requirements' || route.routePath == '/workshop' || route.routePath == '/doc-analyzer' || route.routePath == '/spec-starter' || route.routePath == '/search-documents'
    })
    this.deployArr = this.routeAccessList.filter((route) => { return route.routePath == '/client-print' })
    this.othersArr = this.routeAccessList.filter((route) => { return route.routePath == '/change-log' || route.routePath == '/analytics' })
  }

  onScroll($event) {
    if (this.currentRoute && this.currentRoute.url == '/process-scope') {
      fromEvent($event.target, 'scroll').pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {
        this.authService.dragSelectUpdate$.next(true)
      })
    }
  }

  toggleSideNav(event) {
    this.isSideNavShown = !this.isSideNavShown;
  }

  onMouseHover() {
    this.sideNavState = true;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)
  }

  onMouseLeave() {
    this.sideNavState = false;
    this.panelOpenState = false;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)
  }

  onMenuClick() {
    this.linkText = false;
    this.sideNavState = false;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  triggerConfigAPIs() {
    this.authService.toggleAPILoader(true);
    forkJoin([
      this.authService.getUserRolePermission(),
      this.authService.getUserRoleType()
    ]).subscribe((result) => {
      this.authService.toggleAPILoader(false);
      let rolePermissionData = result[0]['message'];
      let userRoleTypeData = result[1] && result[1]['message'];

      if (userRoleTypeData) {
        this.authService.setInStorage("supportLinks",JSON.stringify(userRoleTypeData["supportLinks"]));
        if (userRoleTypeData['isSuperAdmin']) {
          this.authService.processRolesData(userRoleTypeData);
        }
      }

      if(rolePermissionData) {
        if(userRoleTypeData['isSuperAdmin']) {
          this.authService.setProjectModules(rolePermissionData);
        } else {
          this.authService.processRolesData(rolePermissionData);
          this.authService.setProjectModules(rolePermissionData);
        }
      }
      this.manageMenuItems();
      this.authService.refreshHeader$.next(true);
    }, (err) => {
      this.authService.toggleAPILoader(false);
      console.log(err);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}