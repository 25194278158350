import { Injectable } from '@angular/core';
import { SnackService } from 'src/app/shared/services/snack.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { APP_CONSTANTS } from 'src/app/shared/config/app.constants';
@Injectable({
  providedIn: 'root'
})
export class TimerService {

  defaultTimer = APP_CONSTANTS['sessionIdleTimer'];
  timer = this.defaultTimer;
  idleTimeOutInterval = 0;
  constructor(private auth: AuthService, private snackService: SnackService) { }
  
  handleTimer() {
    this.timer = this.timer - 1;
    this.auth.setInStorage('timer', this.timer);
    if (this.timer <= 0) {
      this.snackService.showSnackMessage(APP_CONSTANTS['sessionExpiry'])
      clearInterval(this.idleTimeOutInterval);
      console.log(this.idleTimeOutInterval)
      console.log('timeout cleared');
      this.auth.isLogout = true;
      this.auth.logout();
    }
  }

  startTimer() {
    this.auth.setInStorage('timer', this.timer);
    if (this.idleTimeOutInterval === 0) {
      this.idleTimeOutInterval = <any>setInterval(() => { this.handleTimer() }, 1000);
    } else if (this.idleTimeOutInterval > 0) {
      this.resetTimer();
    }
  };

  resetTimer() {
    this.timer = this.defaultTimer;
    this.auth.setInStorage('timer', this.timer);
    clearInterval(this.idleTimeOutInterval);
    this.idleTimeOutInterval = 0;
    this.startTimer();
  };
}
