import { Component, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'snack-bar',
    template: `
        <span class="error-msg">{{data}}</span>
    `,
    styles: [`
        .error-msg {
            color: #ff6000;
        }
    `]
})
export class SnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}