import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { RoleService } from '../shared/services/role.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackService } from 'src/app/shared/services/snack.service';
import { APP_CONSTANTS } from 'src/app/shared/config/app.constants';
import { MsalService } from '@azure/msal-angular';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  clientName:any;
  projectId:any;
  scopeType:any;
  pageTitle:any;
  projectName:any;
  showClientData = false;
  isSideNavOpen = false;
  toggleMenuHeader = true;
  toggleConfirmRevoke = false;
  @Input() sideNavState;
  profilePic:any;
  userNameText = '';
  firstAndLastName = '';
  emailId = '';
  deloitteProfilePicURL = 'https://dpn.m.us.deloitte.com/Photos/Index/i_0%C7%B5.t_adfs_';
  @Output() onMouseEnter = new EventEmitter();
  @Output() onMouseLeave = new EventEmitter();
  @Output() onSideNavToggle = new EventEmitter();
  projectsList = [];
  supportLinks:any;
  memberFirm = ''
  project_name = APP_CONSTANTS.project_name;
  shouldFindProject = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private snackService: SnackService,
    public router: Router,
    public roleService: RoleService,
    private msalService: MsalService,
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cd: ChangeDetectorRef) {
      this.authService.refreshHeader$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        if(result) {
          this.shouldFindProject = true;
          this.initHeader();
        }
      });
      this.matIconRegistry.addSvgIcon( "left-nav-btn", this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/left-nav-btn.svg"));
      this.matIconRegistry.addSvgIcon("right-nav-btn",this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/right-nav-btn.svg"));
     }

  ngOnInit() {
    this.initHeader()
  }

  initHeader() {
    this.supportLinks = this.authService.getFromStorage("supportLinks") ? JSON.parse(this.authService.getFromStorage("supportLinks")) : '';
    this.memberFirm = localStorage.getItem('mf') || '';
    if ((this.router.url !== '/project-setup') && (this.router.url !== '/super-admin')) {
      this.clientName = localStorage.getItem('clientName') ? localStorage.getItem('clientName') : '';
      this.projectId = localStorage.getItem('projectId') ? localStorage.getItem('projectId') : ''
      this.scopeType = localStorage.getItem('scopeType') ? localStorage.getItem('scopeType') : ''
      this.pageTitle = localStorage.getItem('pageTitle') ? localStorage.getItem('pageTitle') : ''
      this.projectName = localStorage.getItem('projectName') ? localStorage.getItem('projectName') : ''
      this.showClientData = true;
    }
    this.getUserNameFromEmail();
    this.getUserDetails();
    if(this.roleService.isSuperAdmin()) {
      this.getAllProjects();
    } else {
      this.getUserProjects();
    }

    this.authService.setHeaderName$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isShown: boolean) => {
      setTimeout(() => {
        this.scopeType = localStorage.getItem('scopeType') ? localStorage.getItem('scopeType') : ''
      }, 0);
    });
    this.cd.detectChanges();
  }

  openLink(link){
    window.open(link);
  }

  toggleMenu() {
    this.isSideNavOpen = !this.isSideNavOpen;
    this.onSideNavToggle.emit(this.isSideNavOpen);
  }

  toggleMenuProjectSetting(event:Event){
    event.stopPropagation();
    this.toggleMenuHeader = !this.toggleMenuHeader;
    this.toggleConfirmRevoke = false;
  }

  confirmRevoke(event:Event){
    event.stopPropagation();
    this.toggleConfirmRevoke = !this.toggleConfirmRevoke;
  }

  confirmNoRevoke(){
    event.stopPropagation();
    this.toggleMenuHeader = !this.toggleMenuHeader;
    this.toggleConfirmRevoke = !this.toggleConfirmRevoke;
  }

  onMouseAction() {
    if(!this.sideNavState){
      this.onMouseEnter.emit();
    }else{
      this.onMouseLeave.emit();
    }

  }

  routeToProject(evt, project) {
    if(this.projectId == project.project_id) { return; }
    if(!evt.isUserInput) {return false};
    this.projectName = project.project_name;
    this.getUserRolePermission(project);
  }

  getUserRolePermission(project) {
    this.authService.toggleAPILoader(true);
    this.authService.getUserRolePermission(project.project_id).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.authService.toggleAPILoader(false);
      if (result && result['code'] == 400) {
        this.snackService.showSnackMessage(APP_CONSTANTS['errorRolePermission1'] + (localStorage.getItem('projectAdminEmail') || '') + APP_CONSTANTS['errorRolePermission2']);
        return;
      }
      if (result && result['message'] == "No Project Found") {
        this.snackService.showSnackMessage("No Project Found!");
        if (this.router.url == '/home') {
          window.location.reload();
        } else {
          this.router.navigate(['home']);
        }
      } else if (result && result['message']) {
        this.authService.setInStorage('projectId', project.project_id);
        this.authService.setInStorage('projectName', project.project_name);
        this.projectName = project.project_name;
        if (this.roleService.isSuperAdmin()) {
          this.setProjectModules(result['message']);
        } else {
          this.processRolesData(result['message']);
          this.setProjectModules(result['message']);
        }
        if (this.router.url == '/home') {
          window.location.reload();
        } else {
          this.router.navigate(['home']);
        }
      }
    }, (err) => {
      this.authService.toggleAPILoader(false);
      console.log(err);
    });
  }

  revokeUserAccess(event:Event) {
    event.stopPropagation();
    let rawData = {
      "email": localStorage.getItem('email'),
      "projectId": localStorage.getItem('projectId'),
      "isSelfRevoke": true,
    };
    this.authService.toggleAPILoader(true);
    this.authService.revokeUserAccess(rawData).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.authService.toggleAPILoader(false);
      if (result && result['code'] == 400) {
        this.snackService.showSnackMessage(APP_CONSTANTS['errorDeleteUser'] + (localStorage.getItem('projectAdminEmail') || ''));
        return;
      }
      if (result && result['code'] == 200 && result['message']) {
        this.snackService.showSnackMessage(APP_CONSTANTS['revokeUser']);
        this.authService.removeFromStorage("projectId");
        this.router.navigate(['/login']);
      }
    }, (err) => {
      this.authService.toggleAPILoader(false);
      console.log(err);
    });
  }



  getUserProjects() {
    this.authService.toggleAPILoader(true);
    this.authService.getUserProjects().pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.authService.toggleAPILoader(false);
      if (result && result['code'] == 400) {
        this.snackService.showSnackMessage(APP_CONSTANTS['errorGetUserProjects'] + (localStorage.getItem('projectAdminEmail') || ''));
        return;
      }
      if (result && result['message'] && result['message'] != "No Record Found") {
        this.projectsList = result['message'];
        this.getProjectFromList();
      } else if (result['message'] == "No Record Found") {
        this.projectsList = [];
      }
    }, (err) => {
      this.authService.toggleAPILoader(false);
      console.log(err);
    });
  }

  getAllProjects() {
    this.authService.toggleAPILoader(true);
    this.authService.getAllProjects().pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.authService.toggleAPILoader(false);
      if (result && result['code'] == 400) {
        this.snackService.showSnackMessage(APP_CONSTANTS['errorGetAllProjects'] + (localStorage.getItem('projectAdminEmail') || ''));
        return;
      }
      if (result && result['message'] && result['message'] != "No Record Found") {
        this.projectsList = result['message'];
        this.getProjectFromList();
      } else if (result['message'] == "No Record Found") {
        this.projectsList = [];
      }
    }, (err) => {
      this.authService.toggleAPILoader(false);
      console.log(err);
    });
  }

  processRolesData(data) {
    let roles = data['roles'];
    let roleType = data['roleType'];
    this.authService.setInStorage('roles', JSON.stringify(roles));
    this.authService.setInStorage('roleType', roleType);
  }

  setProjectModules(data) {
    let projectModules = Array.isArray(data['projectModules']) ? data['projectModules'] : [];
    this.authService.setInStorage('projectModules', JSON.stringify(projectModules));
  }

  getUserDetails(){
    this.emailId = this.authService.getFromStorage('email');
    this.firstAndLastName = this.authService.getFromStorage('first_name') + " " + this.authService.getFromStorage('last_name');
  }

  async getUserNameFromEmail() {
    if(this.authService.isAuthenticated) {
      const resp:any = await this.msalService.instance.acquireTokenSilent({ scopes: ["openid", "profile", "User.Read"] });
      this.getUserProfilePicture(resp.accessToken);
      let account:any = this.msalService.instance.getActiveAccount();
      let emailFromProfile = account['username']
      return emailFromProfile.toLowerCase().split('@')[0];
    }
    return ''
  }

  getUserProfilePicture(resp) {

    this.authService.getCurrentUserPhoto(resp).pipe(takeUntil(this.destroy$)).subscribe({
      next: (result: any) => {
        if(result.status === 200) {
          const url = window.URL || window.webkitURL;
          const blobUrl = window.URL.createObjectURL(result.body);
          this.profilePic = this.domSanitizer.bypassSecurityTrustUrl(blobUrl);
        }
      },
      error: (err: any) => {
        this.authService.toggleAPILoader(false);
        if(err.status === 404) {
          this.setNameAsProfilePic();
        }
        console.log(err);
      }
    });
  }

  setNameAsProfilePic() {
    // if (this.adfsService.isAuthenticated) {
    //   let profileDetails = this.adfsService.userInfo && this.adfsService.userInfo['profile'];
    //   this.userNameText = profileDetails['family_name'].charAt(0).toUpperCase() + profileDetails['given_name'].charAt(0).toUpperCase();
    // }
    let fName:any = this.authService.getFromStorage('first_name');
    let lName:any = this.authService.getFromStorage('last_name');
    this.userNameText = fName.charAt(0).toUpperCase() + lName.charAt(0).toUpperCase();

  }

  readImageFile() {
    let img = new Image();
    img.src = this.deloitteProfilePicURL + this.getUserNameFromEmail() +'_MThumb';
    img.onload = () => {
      this.profilePic = img.src;
    }
    img.onerror = () => {
      this.setNameAsProfilePic()
    }

  }

  getProjectFromList() {
    if(this.shouldFindProject) {
      let projectId = localStorage.getItem('projectId');
      let foundProjectDetails = this.projectsList.filter(project => project.project_id === projectId);
      if(foundProjectDetails.length > 0) {
        this.authService.setInStorage('projectName', foundProjectDetails[0].project_name);
        this.authService.setInStorage('scopeType', foundProjectDetails[0].solution_type);
        this.authService.setInStorage('pageTitle', foundProjectDetails[0].solution_desc);
        this.projectName = foundProjectDetails[0].project_name;
        this.pageTitle = foundProjectDetails[0].solution_desc;
        this.scopeType = foundProjectDetails[0].solution_type;
        this.projectId = foundProjectDetails[0].project_id;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
