import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { catchError, switchMap, map, tap, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable, Subject, from, throwError } from 'rxjs';
import { SnackService } from 'src/app/shared/services/snack.service';
import { TimerService } from '../shared/services/timer.service';
import { APP_CONSTANTS } from 'src/app/shared/config/app.constants';
import { MsalService } from '@azure/msal-angular';
@Injectable()
export class HTTPRequestInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  refreshTokenHasFailed = false;
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  constructor(private auth: AuthService,
    private snackService: SnackService,
    private msalService: MsalService,
    private timerService: TimerService) { }
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
  //   if (request.url.includes('s3.amazonaws.com') || request.url.includes('quality_check')) {
  //   return next.handle(request);
  //   }
  //   request = this.addAuthHeader(request);

  //   return next.handle(request).pipe(
  //     tap((event: HttpEvent<any>) => {
  //       if (event instanceof HttpResponse) {
  //         console.log(event);
  //         this.isAPIForbidden(event);
  //         this.updateCsrfToken(event);
  //         this.timerService.startTimer();
  //       }
  //     }),
  //     catchError(error => {

  //     return throwError(() => new Error(error));
  //   }));
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldBypass(request.url)) {
      return next.handle(request);
    }

    return from(this.addAuthHeader(request)).pipe(
      switchMap(authReq => next.handle(authReq)),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log("http-interceptor - event: " + event);
          this.handleResponseEvent(event);
        }
      }),
      catchError(error => {
        if (error.status === 401) {
          if (error.url.indexOf('/logout') > -1) {
            this.snackService.showSnackMessage(APP_CONSTANTS['sessionExpiry']);
            this.auth.isLogout = true;
            this.auth.redirectToLogin();
          } else {
            this.snackService.showSnackMessage(APP_CONSTANTS['sessionExpiry']);
            this.auth.isLogout = true;
            this.auth.logout();
          }
        } else if (error.status == 404) {
          //this.auth.gener
        } else if (error.status == 403) {
          this.snackService.showSnackMessage(APP_CONSTANTS['sessionExpiry']);
          this.auth.isLogout = true;
          this.auth.logout();
        }
        else if(error.status == 402){
          this.snackService.showSnackMessage(APP_CONSTANTS['errorStatusCode'] + (localStorage.getItem('projectAdminEmail') || ''));
        } else if(error.status == 500){
          this.snackService.showSnackMessage(APP_CONSTANTS['errorStatusCode'] + (localStorage.getItem('projectAdminEmail') || ''));
        } else if(error.status == 503){
          this.snackService.showSnackMessage(APP_CONSTANTS['errorStatusCode'] + (localStorage.getItem('projectAdminEmail') || ''));
        } else if(error.status == 504){
          this.snackService.showSnackMessage(APP_CONSTANTS['errorStatusCode'] + (localStorage.getItem('projectAdminEmail') || ''));
        } else if (error.status == 0) {
          let isUserOnline = navigator.onLine;
          if (isUserOnline) {
            console.log(error.message);
          } else {
            this.snackService.showSnackMessage(APP_CONSTANTS['internetIssue']);
          }
        } else if (error.status != 200) {
          console.log(error.message);
        }

        return throwError(() => new Error(error))
      }));
  }

  logoutUser() {
    this.snackService.showSnackMessage(APP_CONSTANTS['sessionExpiry']);
    this.auth.isLogout = true;
    this.auth.logout();
  }

  isAPIForbidden(res) {
    if (res && res.status == 200) {
      if(res.body && res.body['code'] == 403) {
        this.logoutUser();
      }
    }
  }

  updateCsrfToken(res) {
    if (res.headers && res.headers.has('csrf-token')) {
      const csrfToken = res.headers.get('csrf-token');
      this.auth._setCookie('X-XSRF-TOKEN', csrfToken, '');
    }
  }

  private shouldBypass(url: string): boolean {
    return url.includes('s3.amazonaws.com') || url.includes('quality_check') || url.includes('/external/reset') || url.includes('graph.microsoft.com');
  }

  private async addAuthHeader(request: HttpRequest<any>): Promise<HttpRequest<any>> {
    try {
      const resp:any = await this.msalService.instance.acquireTokenSilent({ scopes: ["openid"] });
      //console.log("Response from MSAL Service: " + JSON.stringify(resp,null,'\t'));
      // const entries = Object.entries(resp.idTokenClaims);
      // console.log("entries:" + JSON.stringify(entries));
      // const [key, mfValue] = entries.find(([k]) => k.includes("memberfirm"));
      // let memberfirm:any = mfValue;
      // localStorage.setItem('mf', memberfirm);
      const accessToken = resp.idToken;
      const projectId = this.auth.getFromStorage('projectId');
      const xsrfToken = this.auth.getToken('X-XSRF-TOKEN');

      return request.clone({
        setHeaders: {
          'ngsw-bypass': 'true',
          'access_token': accessToken ? btoa(unescape(encodeURIComponent(accessToken + ':' + projectId))) : '',
          'token_type': 'AAD',
          'x-xsrf-token': xsrfToken ? xsrfToken : '',
          'Vary':'Origin'
        }
      });
    } catch (error) {
      console.error('Failed to acquire token:', error);
      return request.clone(); // Proceed with the original request
    }
  }

  private handleResponseEvent(event: HttpResponse<any>): void {
    this.isAPIForbidden(event);
    this.updateCsrfToken(event);
    this.timerService.startTimer();
  }

  // addAuthHeader(request: HttpRequest<any>) {
  //   let accessTokenRequest = APP_CONSTANTS['msalConfig']
  //   this.msalService.instance.acquireTokenSilent({scopes: ["openid"]}).then(response => {
  //     const accessToken = response.accessToken
  //     const projectId = this.auth.getFromStorage('projectId');
  //     const xsrfToken = this.auth.getToken('X-XSRF-TOKEN');
  //     return request.clone({
  //       setHeaders: {
  //         'ngsw-bypass': 'true',
  //         'access_token': accessToken ? btoa(unescape(encodeURIComponent(accessToken + ':' + projectId))) : '',
  //         'token_type':'AAD',
  //         'x-xsrf-token': xsrfToken ? xsrfToken : ''
  //       }
  //     });
  //   })


  // }

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      this.auth.toggleAPILoader(true);
      return this.auth.generateNewToken().pipe(tap(res => {
        if (res && res['code'] == 200 && res['message']) {
          let data = res['message'];
          if (data) {
            this.auth._setCookie('access_token', data, '');
            this.refreshTokenInProgress = false;
            this.auth.toggleAPILoader(false);
            this.tokenRefreshedSource.next(true);
          }
        }
      }));
    }
  }
}

export const HTTPRequestInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HTTPRequestInterceptor, multi: true }
];
