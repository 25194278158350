export const baseURL = 'https://6m0y9x3ha8.execute-api.us-gov-east-1.amazonaws.com/dev';
export const baseURL2 = 'https://6m0y9x3ha8.execute-api.us-gov-east-1.amazonaws.com/dev';
export const analyzerURL = 'https://5045m2sodd.execute-api.us-east-1.amazonaws.com/';
export const AryaAPI = 'https://6m0y9x3ha8.execute-api.us-gov-east-1.amazonaws.com/dev';
export const auth_token = 'x5ib155MtB1KbKUfmp2bU1dkJI1g6nD3eUdqyYa0';
export const SAP_URI = 'https://dttpocus.prod.apimanagement.us10.hana.ondemand.com/AQI_ConfigAut';
export const ECC_URI = 'https://dttpocus.prod.apimanagement.us10.hana.ondemand.com/ECC_ConfigAut';
export const GenAIURL = 'https://6m0y9x3ha8.execute-api.us-gov-east-1.amazonaws.com/dev';
export const GENERATE_CONTENT_API = 'https://rx6o2afvkcudn3nk4iyvtoumpi0hypxm.lambda-url.us-east-1.on.aws/';
export const ChatBotKX = 'https://6m0y9x3ha8.execute-api.us-gov-east-1.amazonaws.com/dev';

export const environment = {
  production: true,
  processScopeServiceAPI: baseURL + '/process/scope',
  createProcessScope: baseURL + '/process/createscope',
  updateProcessScopeServiceAPI: baseURL + '/process/createscope',
  ehiServiceAPI: baseURL + '/touchless/status',
  projectSetupAPI: baseURL + '/project/createproject',
  goTouchlessProcessServiceAPI: baseURL + '/touchless/executeBods',
  getProjectDetails: baseURL + '/project/projectdetail',
  updateProjectSetup: baseURL + '/project/update',
  getAllProjects: baseURL + '/auth/listprojects',
  getUserProjects: baseURL + '/auth/userprojects',
  saveBOTSDetails: baseURL + '/project/savebotsdetails',
  saveBODSDetails: baseURL + '/project/savebodsdetails',
  saveSharePointDetails: baseURL + '/project/savesharepointdetails',
  saveOutlookDetails: baseURL + '/project/saveoutlookdetails',
  saveTargetSystemDetails: baseURL + '/project/savetargetsystemdetails',
  saveALMDetails: baseURL + '/project/savealmdetails',
  saveIP5Details: baseURL + '/project/saveipdetails',
  updatePWD: baseURL + '/user/updatePwd',
  login: baseURL + '/auth/login',
  saveUsers: baseURL + '/user/createuser',
  createSprint: baseURL + '/project/createsprint',
  updateSprint: baseURL + '/project/updatesprint',
  deleteSprint: baseURL + '/project/deletesprint',
  createPassword: baseURL + '/auth/password',
  createSources: baseURL + '/project/createsource',
  updateSources: baseURL + '/project/updatesource',
  getL1List: baseURL + '/process/l1Process',
  downloadProcessScopeServiceAPI: baseURL + '/process/downloadscope',
  editLockAPI: baseURL + '/process/lockscope',
  getRoles: baseURL + '/roles/getroles',
  getUserRolePermission: baseURL + '/user/rolepermission',
  updateTargetSystemDetails: baseURL + '/project/updateTarget',
  updateProjectStatus: baseURL + '/project/updatestatus',
  createNewL1: baseURL + '/process/createl1',
  projectRuleAPI: baseURL + '/project/rulengine',
  getWorkshopDetails: baseURL + '/workshop/workshopdetails',
  createWorkshop: baseURL + '/workshop/createworkshop',
  getListofWorkshops: baseURL + '/workshop/workshoplist',
  editWorkshopDetails: baseURL + '/workshop/editworkshop',
  deleteWorkshop: baseURL + '/workshop/deleteworkshoprows',
  getWorkshopNotes: baseURL + '/workshop/workshopnotes',
  deleteWorksopNotes: baseURL + '/workshop/deleteworkshopnotes',
  addUpdateNotes: baseURL + '/workshop/modifynotes',
  getResultTracker: baseURL + '/workshop/resulttracker',
  getPreReadEmail: baseURL + '/workshop/prereadtemplate',
  reserveTime: baseURL + '/workshop/reservetime',
  ip5draftuploadstatus: baseURL + '/workshop/ip5draftuploadstatus',
  pingAddress: baseURL + '/project/ping',
  generateWorkshopDeck: baseURL + '/workshop/generateworkshopdeck',
  downloadPreSignedUrl: baseURL + '/project/downloadurl',
  uploadPreSignedUrl: baseURL + '/touchless/uploadurl',
  downloadWorkshop: baseURL + '/workshop/downloadworkshopplan',
  downloadWorkshopNotes: baseURL + '/workshop/downloadworkshopnotes',
  gethomeinfo: baseURL + '/process/dashboard',
  performExtract: baseURL + '/touchless/autointake',
  performHarmonize: baseURL + '/touchless/harmonize',
  performImplement: baseURL + '/touchless/implement',
  performFileControl: baseURL + '/touchless/filecontrol',
  getRequirements: baseURL + '/requirements/getrequirements',
  saveRequirement: baseURL + '/requirements/createrequirement',
  updateRequirement: baseURL + '/requirements/updaterequirement',
  downloadRequirement: baseURL + '/requirements/downloadrequirement',
  deleteRequirement: baseURL + '/requirements/deleterequirement',
  updateRequirementSprint: baseURL + '/requirements/massupdatesprint',
  lockRequirementEdit: baseURL + '/requirements/lockrequirement',
  generateToken: baseURL + '/auth/generatetoken',
  massStatusUpdate: baseURL + '/project/statusupdate',
  migrateRequirments: baseURL + '/project/migraterequirments',
  migrateRicefw: baseURL + '/project/migratericefw',
  createricefw: baseURL + '/project/createricefw',
  getricefw: baseURL + '/project/getricefw',
  downloadricefw: baseURL + '/project/downloadricefw',
  updatericefw: baseURL + '/project/updatericefw',
  deletericefw: baseURL + '/project/deletericefw',
  splitricefw: baseURL + '/project/splitricefw',
  updatel2sprint: baseURL + '/project/updatel2sprint',
  getsprint: baseURL + '/project/getsprint',
  mergericefw: baseURL + '/project/mergericefw',
  duplicatericefw: baseURL + '/project/duplicatericefw',
  getL3List: baseURL + '/process/getl3',
  removeUser: baseURL + '/user/removeuser',
  downloadUsers: baseURL + '/project/downloadprojectusers',
  logout: baseURL + '/user/logout',
  deleteuniquericefw: baseURL + '/project/deleteuniquericefw',
  versions: baseURL + '/project/versions',
  abort: baseURL + '/project/abort',
  getEnhancementRICEFWList: baseURL + '/configscope/enhancementlistricefw',
  getConfigScope: baseURL + '/configscope/getconfigscope',
  configDocUpdate: baseURL + '/configscope/configdocupdate',
  deleteDesignDecision: baseURL + '/configscope/deletedesigndecision',
  getConfigDocActivityTracing: baseURL + '/configscope/getactivitytracing',
  getConfigScopeUsers: baseURL + '/configscope/users',
  getComments: baseURL + '/configscope/getcomments',
  postComments: baseURL + '/configscope/postcomments',
  lockConfigScope: baseURL + '/configscope/lock',
  createConfigNode: baseURL + '/configscope/createconfigscope',
  newL4: baseURL + '/clientprint/new',
  getTCodes: baseURL + '/clientprint/tcodes',
  getBusinessRoles: baseURL + '/clientprint/businessroles',
  postL4: baseURL + '/clientprint/l4',
  getRequirementClientPrint: baseURL + '/clientprint/requirement',
  getRicefwClientPrint: baseURL + '/clientprint/ricefw',
  saveChangeLogActivity: baseURL + '/clientprint/captureactivitylog',
  getChangeLog: baseURL + '/clientprint/getactivitylog',
  deleteL4: baseURL + '/clientprint/delete',
  createUpdateReqClientPrint: baseURL + '/clientprint/required',
  createUpdateRICEFWClientPrint: baseURL + '/clientprint/ricefw',
  updateNodeStatus: baseURL + '/configscope/updatestatus',
  updateNodeAssignee: baseURL + '/configscope/updateassigneduser',
  createOrUpdateL2: baseURL + '/clientprint/createupdatel2',
  createOrUpdateL3: baseURL + '/clientprint/createupdatel3',
  updateL1: baseURL + '/clientprint/updatelevelone',
  generateReport: baseURL + '/clientprint/report',
  lockClientPrintScope: baseURL + '/process/lock',
  searchsupportgetxref: baseURL + '/xref/searchsupportgetxref',
  savexrefdata: baseURL + '/xref/savexrefdata',
  roletype: baseURL + '/auth/roletype',
  saveAnalytics: baseURL + '/project/saveanalytics',
  deleteAnalytics: baseURL + '/project/removeanalyticsdata',
  projectModules: baseURL + '/project/getmastermodules',
  downloadMasterRules: baseURL + '/project/getmasterrulesdownload',
  saveProjectModules: baseURL + '/project/savemodules',
  getGroupRequirements: baseURL + '/requirements/getgrouprequirements',
  getGroupRicefw: baseURL + '/ricefw/getgroupricefw',
  saveHyperScalerDetails: baseURL + '/project/savehyperscalerdetails',
  getHyperScalerPresignedURL: baseURL + '/touchless/downloadharmonizationurl',
  saveWorkshopInvite: baseURL + '/project/workshopinvite',
  onBusinessRulesFileUpload: baseURL + '/process/insertprojectrules',
  onIP5FileUpload: baseURL + '/project/insertprojectscope',
  deleteClientPrint: baseURL + '/clientprint/deleteclientprintprocess',
  getSimilarityScore: baseURL + '/project/getsimilarityscore',
  updateAryaIntegration: baseURL + '/project/updatearyaintegration',
  downloadXrefData: baseURL + '/xref/downloadxrefdata',
  updateProjectAttributes: baseURL + '/project/updateprojectattributes',
  projectattributesvalidation: baseURL + '/project/projectattributesvalidation',
  addEmail: baseURL + '/project/addemail',
  updatetoggleRules: baseURL + '/project/updatetogglerules',
  saveAhiConfigDetails: baseURL + '/project/addahiurls',
  getAllSolutionScopes: baseURL + '/auth/getallsolutionscopes',
  downloadTemplates: baseURL + '/auth/downloadtemplates',
  uploadSuperAdminTemplate: baseURL + '/auth/superadmin',
  downloadMasterData: baseURL + '/auth/superadmindownload',
  getProjectList: baseURL + '/auth/industryspecificprojects',
  downloadProjectData: baseURL + '/auth/downloadprojectdata',
  previousRunSourceSystems: baseURL + '/project/previousrunsourcesystems',
  selectedRunSourceSystems: baseURL + '/project/selectedrunsourcesystems',
  getRICEFWType: baseURL + '/requirements/questionnairedata',
  saveProposal: baseURL + '/requirements/PostquestionnaireData',
  saveLeverage: baseURL + '/requirements/createricefwfromproposal',
  getRICEFWDoc: baseURL + '/requirements/getricefwdocument',
  uploadSuperAdminQuesTemplate: baseURL + '/auth/superadmin',
  downloadQuesTemplate: baseURL + '/auth/downloadtemplates',
  downloadQuesData: baseURL + '/auth/superadmindownload',
  deleteDataRecon: baseURL + '/project/removedatareconciliation',
  saveDataRecon: baseURL + '/project/savedatareconciliation',
  uploadPresignedURL: baseURL + '/requirements/getuploadsignedurl',
  docAnalyzer: analyzerURL + "test_poc/quality_check",
  saveDocSettings: baseURL + '/project/savedocumentsettings',
  downloadProjReq: baseURL + '/project/getprojectrequirementsdownload',
  downloadProScope: baseURL + "/project/downloadprojectscopetemplate",
  saveProjReq: baseURL + '/project/projectrequirementmassupload',
  saveDocUUID: baseURL + '/project/saveuuiddocanalyzer',
  getDocUUID: baseURL + '/project/getuuiddocanalyzer',
  getDocUUIDDetail: baseURL + '/project/getuuiddetaildocanalyzer',
  getConfigFitRequirements: baseURL + '/configscope/getfitrequirements',
  saveRequirementWithNodes: baseURL + '/configscope/updatemappinginfo',
  getRequirementConfigNodes: baseURL + '/configscope/getmappinginfo',
  updateUserStatus: baseURL + '/configscope/updateontype',
  updateRequirementConfig: baseURL + '/configscope/updatevariantinfo',
  getRootNodes: SAP_URI + '/ETY_CONFIG_TREESet',
  getVariantName: SAP_URI + '/ETY_SEQ_HEADSet',
  getConfigSequencer: SAP_URI + '/ETY_SEQ_ITEMSet',
  downloadleafnode: SAP_URI + '/ETY_DOWNLDSet',
  leafNode: SAP_URI + '/ETY_CONFIG_HEADSet',
  getODataSummary: SAP_URI + '/ETY_STATUSSet',
  getviewPop: SAP_URI + '/ETY_LOGSet',
  saveConfigAutomationDetails: baseURL + '/project/saveconfigautomationurls',
  saveConfigUsers: baseURL + '/configscope/postuserinfo',
  configDoc : SAP_URI + '/ETY_DOCSet',
  noProposalSelected: baseURL + '/requirements/noproposalselected',
  viewConfig : SAP_URI + '/ETY_EXEUTE_LINKSet',
  getDocSignedUrlDownload: baseURL + '/requirements/docsignedurldownload',
  registerUser: baseURL + '/auth/createuser',
  getConfigScenarios: baseURL + '/configscope/getscenariodata',
  restrictUser: baseURL + '/user/rejectuser',
  getMLVariants: baseURL + '/configscope/getmlvariants',
  getkxresponse: baseURL + '/requirements/getkxresponse',
  downloadKXDocs: baseURL + '/requirements/downloadKXDocs',
  fetchTR_NO :  SAP_URI + '/ETY_TRANSPORT_REQUESTSet' ,
  fetchOtherUser : SAP_URI + '/ETY_USERSet',
  getCode: baseURL + '/requirements/getcodesnippetlist',
  getCodeOnExpand: baseURL + '/requirements/getcodesnippeturl',
  generateContent: GENERATE_CONTENT_API,
  regenerateContent : GenAIURL + '/regenerate_content',
  downloadLegacy : ECC_URI + '/ETY_DOWNLDSet',
  saveVariantNodesLegacy : ECC_URI + '/ETY_SEQ_HEADSet',
  syncConfigAutomation_S4 : baseURL + '/configscope/s4hanavariantnodes',
  syncConfigAutomation_ECC : baseURL + '/configscope/legacyvariantnodes',
  generateDocument: baseURL + '/requirements/invokespecgen',
  retrieveContent : baseURL + '/requirements/invokespecgen',
  genContent: baseURL + '/requirements/invokespecgen',
  syncConfigAutomation_reqtID : baseURL + '/configscope/legacyvariantnodesreqidblank',
  resetExtract: baseURL + '/external/resetautointake',
  resetHarmonize: baseURL + '/external/resetharmonize',
  resetImplement: baseURL + '/external/resetimplement',
  bearerTokenResetAPI: 'NDE1YzQwMTA0ODUzODZiNjQ5MGJkOTM0ZWVlMWM5MTA6NTI0OTMyNzRkODJjMjJmOTMyNTg3NTgxOTNiMmQyNDg6Qk9EUw====',
  contentpooling:  baseURL + '/requirements/contentpooling',
  invokerKX: baseURL + '/requirements/docinvoker',
  chatbotAPI: baseURL + '/requirements/askthebot',
  trackingExclusion : baseURL + '/project/saveprojecttrackingexclusion',
  projectArchiveAPI : baseURL +'/superadmin/archiveproject',
  uploadFSTemplate : baseURL + '/project/getricefwtemplatedetails',
  saveFSTempUpload : baseURL + '/project/savericefwtemplatedetails',
  getdefaultricefwtemplatedetails : baseURL + '/requirements/getdefaultricefwtemplatedetails',
  tempStatusUpload : baseURL + '/project/getricefwtemplatestatus',
  saveObjectType : baseURL + '/project/savetemplate',
  getRICEFWDownload : baseURL + '/requirements/fspollingresponse',

};
