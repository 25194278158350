export const APP_CONSTANTS = {
    configScopeLog: {
        pending: '#0CA5F2',
        success: '#37BF4F',
        failed: '#F51009'
    },
    processScopeColorMap: {
        foundation: '#C128EC',
        strategic: '#0AB934',
        core: '#EB7F00',
        inScope: '#2DC6C3',
        outOfScope: '#E03200'
    },
    docAnalyzerColorMap: {
        docAnalyzed: '#bac2e2',
        looksGood: '#0ab934',
        needsCorrection: '#eb7f00',
        minorIssues: '#e03200'

    },
    ricefwColorMap: {
        approved: '#2444E2',
        created: '#EC2C93',
        inProgress: '#ADA8A0'
    },
    reqColorMap: {
      approved: '#2444E2',
      rejected: '#ef3501',
      pendingApproval: '#2DC6C3',
      new: '#EC2C93',
      deferred: '#ADA8A0',
      duplicate: '#29cccc'
    },
    rulesColorMap: {
        completed:'#0ab934',
        notCompleted:'#d1a239'
    },
    workshopPlanningPanelItems: {
        workshop_code: 'CODE',
        workshop_title: 'NAME AND TYPE',
        owner_fullname: 'OWNER',
        lead_fullname: 'LEAD',
        team: 'TEAM',
        date: 'DATE',
        starttime: 'TIME(UTC)',
        reserve: 'RESERVE',
        preEmail: 'PRE-READ EMAIL',
        contentstatus: 'CONTENT'
    },
    workshopExecutionPanelItems: {
        workshop_code: 'CODE',
        workshop_title: 'NAME AND TYPE',
        owner_fullname: 'OWNER',
        team: 'TEAM',
        date: 'DATE',
        starttime: 'TIME(UTC)',
        workshopNotes: 'WORKSHOP NOTES',
        resultTracker: 'RESULT TRACKER'
    },
    workshop: {
        typeList: ['Process Design Workshop', 'Integrated Design Workshop', 'Technical Design Workshop', 'Detail Design Workshop'],
        teamList: ['RTR', 'OTC', 'PTP', 'PTM', 'INT', 'DEV', 'Finance']
    },
    workshopEmailConst: {
      preRead: 'PRE-READ EMAIL',
      resultTracker: 'RESULT TRACKER',
    },
    s3Bucket: 'ke-project-27mar2020',
    ricefwPanelItems: {
        rid: 'ID',
        title: 'TITLE',
        requirement: 'REQ ID & DESCRIPTION',
        type: 'TYPE',
        complexity: 'COMPLEXITY',
        ip5mapping: 'IP5 MAPPING',
        team: 'TEAM',
        processlead: 'LEAD',
        status: 'STATUS'
    },
    ricefw : {
        complexityList: ['High', 'Medium', 'Low'],
        ricefwTypeList: ['Report', 'Interface', 'Conversion', 'Enhancement', 'Form', 'Workflow'],
        statusList: ['New', 'Proposed', 'Approved']
    },
    ricefwStatusList: ['New', 'Proposed', 'Approved', 'Merged'],
    ricefwGlobalStatusList: { Proposed: 'Send for Approval', Approved: 'Approve', Rejected: 'Reject', Deferred: 'Defer' },
    requirementListPanelItems: {
        uuid: 'ID',
        workshop_captured: 'Workshop Captured',
        team: 'TEAM',
        mentioned_by: 'MENTIONED BY',
        l2_title: 'IP NAME & CODE',
        localization: 'LOCALIZATION',
        fit_gap: 'FIT/GAP',
        status: 'STATUS'
    },
    localizationStatus: ['Yes','No'],
    fitGap: ['Fit','Gap'],
    sessionIdleTimer: (60*30),
    industries: [
        { name: "Consumer", sectors: ["Automotive", "Consumer Products", "Retail, Wholesale & Distribution", "Transportation, Hospitality & Services"] },
        { name: "Energy, Resources & Industrials", sectors: ["Industrial Products & Construction", "Mining & Metals", "Oil, Gas & Chemicals", "Power & Utilities"] },
        { name: "Financial Services", sectors: ["Banking & Capital Markets", "Insurance", "Investment Management", "Real Estate"] },
        { name: "Government & Public Services", sectors: ["Civil Government", "Defense, Security & Justice", "Health & Social Care", "International Donor Organization", "Transport"] },
        { name: "Technology, Media & Telecommunications", sectors: ["Technology", "Telecom, Media & Entertainment"] },
        { name: "Life Sciences & Health Care", sectors: ["Life Sciences", "Health Care"] }
    ],
    scopeTypes: [
        {scope: "KFS (Kinetic Finance Solution)"},
        {scope: "KCP (Kinetic Central Procurement)"},
        {scope: "KE (Kinetic Enterprise)"},
    ],
    mappingEntity: [
      {name: "Industry Print", key: "industryPrint"},
      {name: "Config Scope", key: "configScope"},
      {name: "Workshop to IP5 Mapping", key: "workshopToIp5Mapping"},
      {name: "Requirement to IP5 Mapping", key: "requirementToIp5Mapping"},
      {name: "IMG Nodes", key:"l2ImgNodes"}
    ],
    solution: [
      {name: "KFS (Kinetic Finance Solution)", value: "KFS"},
      {name: "KCP (Kinetic Central Procurement)", value: "KCP"},
      {name: "KE (Kinetic Enterprise)", value: "KEE"}
    ],

    documentPanelQA: {
        name: 'DOCUMENT NAME',
        score: 'SCORE',
        author: 'AUTHOR'
    },

    configSummary: {
        totalEntries : '#BAC2E2',
        success:'#0AB934',
        errors:'#E03200',
        partialSuccess:'#EB7F00'
    },
    ViewConfigError: "Cannot view this Configuration!",
    SAPDataSave: "Data saved successfully!",
    addNodeError: "Select atleast one Parent Node!",
    NoDataForSequencer: "No Data Available!",
    fitSelectionError: "Select Fit Requirement First!",
    fitSelectionNotSelectedError : "Select atleast one Fit Requirement!",
    dataNotAvailable:"No results found. Please search with a different keyword or phrase",
    successfullyRiceFWMessageGenerate : "User proposals saved successfully",
    userTableColumns: ['First Name', 'Last Name', 'Email ID', 'Role', 'Process','Action'],
    frequency: ['NONE', 'HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY'],
    sourceTypes: ["SAP ECC", "SAP S4", "Oracle", "JDE", "Others"],
    targetSourceTypes: ["SAP S4 HANA", "SAP ECC", "JD Edwards", "PeopleSoft", "Others"],
    ruleTypeSR : "SR",
    somethingWentWrong: 'Something went wrong, Please try again!',
    noRecordFound: 'No results available to show',
    sessionExpiry: 'Session Expired. Please Login Again',
    errorStatusCode: 'Unable to process request due to Network Error, please contact Project Administrator at ',
    saveData: 'Please Save your data',
    L2L3ValidationMsg: 'Please enter Process names for L2 & L3 to proceed further',
    L2L3ValidationIdMessage : 'Please enter Process Id for L2 & L3 to proceed further',
    L2L3ValidationDuplicateValue : 'Process Id for L2 & L3 already exists, Please enter new Process Id to proceed further',
    L2L3ValidationDuplicateProcessId: 'Process Id Prefix for L2 & L3 not matching, Please enter proper Process Id Prefix to proceed further',
    L2L3NamingConvention: 'Please follow the naming convention of Procees Id',
    L2L3Zero: 'Please add zero for Two Digit Process Id',
    projectStatusUpdate: 'Successfully Updated Project Status',
    deleteUser: 'Successfully deleted user',
    revokeUser: 'Successfully revoked user',
    restrictedUser: 'Successfully restricted user',
    createUser: 'Changes saved successfully',
    newUser:"Users have been added successfully",
    existingUser:"Details saved successfully",
    createProject: 'Project Created Successfully',
    updateProject:  'Successfully updated project details',
    deleteSprint: 'Successfully Deleted Sprint',
    updateSprint: 'Successfully Updated Sprint',
    createSprint: 'Successfully Created Sprint',
    updateSource: 'Succesfully updated Source details',
    saveSource: 'Succesfully saved Source details',
    updateIP5:'Succesfully updated IP5 details',
    updateTargetSys:'Succesfully updated Target system details',
    updateBODS:'Succesfully updated BODS details',
    updateALM: 'Succesfully updated ALM details',
    updateBOTS: 'Succesfully updated BOTS details',
    updateSharePoint: 'Succesfully Updated Share Point Information',
    updateOutlook: 'Succesfully Updated Outlook Information',
    saveAnalytics: 'Successfully Created Report',
    deleteAnalytics: 'Successfully Deleted Report',
    selectLabels: 'Please Select 8 Labels',
    selectMaxLabels: 'Please Select Max 8 Labels',
    reqMigrate: 'Requirements migrated successfully',
    reqUpdate: 'Requirement(s) updated successfully',
    reqCreate: 'New Requirement ',
    successfully: ' created successfully',
    someoneEditingReq: ' is already editing this requirement',
    noWorkshops: 'No workshops are available to show',
    selectWorkshop: 'Please select workshop code to proceed furthur!',
    ricefwDelete: 'Selected RICEFW(s) are deleted successfully',
    ricefwStatusRevert: 'You can\'t revert back the status to Created!',
    noReqs: 'No Requirements found',
    ricefwUpdate: 'RICEFW updated successfully',
    ricefwCreate: 'RICEFW created successfully',
    ricefwStatusUpdate: 'Status updated successfully',
    ricefwStatusUpdateFailed: 'Status update failed, Please try again',
    selectCreatedStatusRicefw: 'Please select the RICEFW\'s with status New',
    ricefwMerge: 'RICEFW Merge Completed successfully',
    ricefwMergeFailed: 'RICEFW Merge failed, Please try again',
    ricefwMergeInvalidType: 'Oops! Can\'t merge with different RICEFW types',
    ricefwMergeL2Mismatch: "Oops! Selected RICEFW's L2's do not match",
    ricefwDupalicate: 'Selected RICEFW has been Duplicated successfully',
    ruleApplied: 'Rule Applied SuccessFully',
    ruleCanNotAppliedAsHarmonizeStarted: 'Unable to apply changes while Harmonize is still in progress. Refreshing the page to fetch the latest data.',
    mandatoryFields: 'Please provide the mandatory information to proceed further',
    workshopCreated: 'Workshop created Successfully',
    fileUploaded: 'File uploaded successfully',
    finalContentFailed: 'Final content not available, Please try again',
    reqMigrationFailed: 'Failed to Migrate Requirements',
    idleTimerExpired: 'Session Expired. Please Login Again',
    l1Exists: 'L1 Code already exists. Please try with different one',
    workshopNotesDelete: 'Successfully deleted Workshop note(s)',
    savewWorkshopNotes: 'Successfully saved Workshop note(s)',
    validationMsg: "Value is currently in use, cannot perform this action",
    onSaveAppbotEmail: 'Email is successfully Saved',
    projectAdmin: 'Project Admin',
    superAdmin: 'SuperAdmin',
    s3bucketErrorMessage :'Document enrichment is in progress & suppose to get uploaded for the selected proposals',
    s3bucketErrorMessageDow :'Document suppose to get downloaded for the selected file',
    codeSnippet:'Downloaded Code Snippet',
    downloadedTSFSCS:'Downloaded Documents',
    codeSnippetNotAvailable:'Code Snippet not available!',

    adfsConfig: {
      instance: 'https://login.microsoftonline.com/',
      tenant: '36da45f1-dd2c-4d1f-af13-5abe46b99921',
      clientId: '4e2bcfe9-d61c-4681-b309-883d4a164fad',
      redirectUri: 'https://kfs-dev.deloittefed.com',
      cacheLocation: 'localStorage',
      postLogoutRedirectUri: 'https://kfs-dev.deloittefed.com',
      localLoginUrl: 'https://kfs-dev.deloittefed.com/login'
    },
    msalConfig: {
      clientId: '4e2bcfe9-d61c-4681-b309-883d4a164fad',
      authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
    invalidDateSelected: 'End Date cannot be before Start Date',
    editingProcessscope:'is already editing the process',
    requirmentStatusList: ['New', 'Deferred', 'Approved', 'Duplicate', 'Proposed', 'Rejected'],
    analyticsConfig: {
        baseURL: 'https://app.powerbi.com/reportEmbed?reportId=',
        reportId: '5ce55f1e-1bec-4d65-814c-00bf3ab9220f',
        autoAuth: '&autoAuth=true',
        ctid: '&ctid=36da45f1-dd2c-4d1f-af13-5abe46b99921',
        config: '&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D'
    },
    configPanelItems_L3: {
        sproCustomizationPoint: 'SPRO CUSTOMIZING POINT',
        assign_to: 'ASSIGNED TO',
        status: 'STATUS',
    },
    configAutomationPanelItems_L3: {
        req_desc: 'FIT REQUIREMENTS',
        config_assignment: 'ASSIGNED TO',
        status: 'STATUS',
    },
    docAnalyzerPanel: {
        "DocumentName": "Document Name",
        "Score": "Score",
        "ViewDetails" : "View Details",
    },
    configStatusList: ['Not Started','Started','In Progress', 'Completed','Approved'],
    processTeamMember: 'Process Team Member',
    businessTeamMember: 'Business Team Member',
    processLead: 'Process Lead',
    productOwner: 'Product Owner',
    postCommentSuccess: 'Successfully posted comments',
    deleteDesignDecission : 'Design decision(s) deleted successfully',
    saveConfigNode: 'Successfully updated configuration node',
    updateConnDetails: 'Successfully updated connectivity details',
    createConfigNode: 'Successfully added SPRO customization point',
    processScopeClassification: ['foundation', 'core', 'strategic'],
    levelZeroList: ['Infrastructure', 'Operations', 'Collaborations'],
    clientPrintTeamsList: ['OTC', 'RTR', 'PTP', 'MDG', 'SCM', 'EWM'],
    changePriority: ['Critical', 'High', 'Medium', 'Low', 'Defer'],
    changeImplication: ['Difference', 'Implication'],
    changeImapctType: ['Business Process Change', 'Organizational Change', 'System Change', 'Policy and Procedure Change'],
    clientPrintFreq: ['On demand', 'Continuous', 'Every 15 minutes', 'Every 30 minutes', 'Hourly', 'Daily', 'Weekly', 'Biweekly', 'Semimonthly', 'Monthly', 'Bimonthly', 'Quarterly', 'Semiannually', 'Annually', 'Biannually'],
    packageFlagList: ['SAP BO', 'SAP ECC', 'SAP BW', 'SAP CRM', 'S4 Hana', 'SAP Solution Manager', 'SAP SRM', 'SAP SuccessFactors', 'Manual', 'Legacy'],
    changeLogPanelItems: {
        page_name: 'Page',
        page_element: 'Page Element',
        action: 'Action',
        add_date: 'DATE',
        user_uuid: 'Changed By'
    },
    fillConfigNodeDetails: 'Please add SPRO Title, Status, Assignee before you edit',
    deleteL4: 'Successfully deleted L4',
    selectLevel: 'Please select level(s) to edit',
    selectLevelToEdit: 'Oops! Please click update to edit any level',
    statusUpdate: 'Updated Status for selected SPRO customization point(s)',
    assigneeUpdate: 'Updated Assignee for selected SPRO customization point(s)',
    ricefwsUpdate: 'Successfully Mapped L4(s) to selected RICEFW(s)',
    ricefwsUnmapUpdate: 'Removed L4 mapping from selected RICEFW(s)',
    reqsUpdate: 'Successfully Mapped L4(s) to selected Requirement(s)',
    reqsUnmapUpdate: 'Removed L4 mapping from selected Requirement(s)',
    addNewL1: 'Successfully created new L1',
    addNewL2: 'Successfully created new L2',
    addNewL3: 'Successfully created new L3',
    addNewL4: 'Successfully created new L4',
    updateL1: 'Successfully updated L1',
    updateL2: 'Successfully updated L2',
    updateL3: 'Successfully updated L3',
    updateL4: 'Successfully updated L4',
    saveProjectAttributes:"Project Attributes saved successfully",
    noProjectFound: 'No Project found for the Industry and Industry Sector and Project Solution combination',
    saveToggleRules: "Toggle Rules for the current run version updated successfully",
    docGenerateInProgress: 'Document Generation is in Progress. Please try again after sometime',
    xrefDetailsSaved: 'XREF Details saved successfully',
    xrefSearchNoResult: 'No Data Found for entered search input. Clicking reset button will display all results',
    xrefNoData: 'No Data Found for this run version',
    saveHyperscalerDetails: 'Successfully saved hyperscaler details',
    internetIssue: 'Request Failed. Please check your internet connection',
    saveModules: 'Successfully saved project modules details',
    projectExist: 'Project with same project id already exist.',
    saveSourceSystem: 'Source System(s) Configuration saved successfully',
    addBlankAttribute: 'Please enter a valid attribute',
    noTemplateFound: 'No Template found',
    mappingEntityUpload: 'Template has been uploaded successfully',
    errorGroupRequirement: 'Failed to retrieve Group Requirement!',
    errorGroupRicefw: 'Failed to retrieve Group Ricefw!',
    questionnaireEntityUpload: 'Questionnaire Template uploaded successfully',
    errorUploadFileType :'Questionnaire Field type not matching',
    //Process Scope error Handling
    errorRetrieveProcessScopeData: 'Failed to retrieve process scope details',
    errorSaveProcessScopeData: 'Unable to save changes , please try again later. If issue persists, please contact Project Administrator at ',
    errorDownloadProcessScope: 'Unable to download report , please try again later. If issue persists, please contact Project Administrator at ',
    errorGetProcessScopeList: 'Failed to retrieve process scope list',
    erroraddNewL1: 'Unable to add New L1 record, Please try again',
    errorLockScope: 'Unable to save changes , please try again later. If issue persists, please contact Project Administrator at ',
    errorlockLevel: 'Failed to lock the level',
    errorHomeinfo: 'Industry Sector Print not available in the Library . Please contact Project adminstrator at ',
    errorGetRoles: 'Unable to get Role details , please try again later. If issue persists, please contact Project Administrator at ',
    errorGetL1Data: 'Industry Sector Print not available in the Library . Please contact Project Administrator at ',
    //ConfigScope error handling
    errorConfigScopeData: 'Failed to get Configscope details',
    errorLevelRelatedData: 'Unable to show Enhancement information, please try again later. If issue persists , please contact Project Administrator at ',
    errorGenerateDocument: 'Failed to generate Configuration Document',
    errorDeleteDesignDecision: 'Failed to delete Design decision',
    errorActivityTracing: 'Failed to perform Activity Tracing',
    errorGetComments: 'Failed to retrieve Comments',
    errorPostComments: 'Failed to post Comments',
    errorUnlockConfigNode: 'Failed to unlock Config Node',
    errorLockConfigNode: 'Failed to unlock Config Node',
    errorCreateorUpdateNode: 'Failed to Create or Update Config Node',
    errorUpdateNodeStatus: 'Failed to update Status for selected SPRO customization point(s)',
    errorUpdateAssignee: 'Failed to update Assignee for selected SPRO customization point(s)',
    deleteVariant : 'Failed to Delete the Variant',
    SuccessdeleteVariant : 'Successfully deleted the Variant',
    noTransportNoDetails : 'No Transport requests are available to show!',
    //Requirements error handling
    errorGetCodePreview: 'Unable to load Code Preview, please try again later. If issue persists , please contact your system administrator',
    errorGetRequirementsList: 'Unable to load Project requirements, please try again later. If issue persists , please contact your system administrator',
    errorGetGenAI: 'Error while generating content',
    pleaseWaitAPI: 'Please wait while we process your request. The OpenAI API will be taking longer than usual to respond. Thank you for understanding',
    errorGetProposals: 'Unable to load Proposals, please try again later. If issue persists , please contact your system administrator',
    errorNoProposals: 'No matching specs found for the selected requirement & questionnaire data',
    errorCreateorUpdateRequirement: 'Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ',
    errorDownloadRequirement: 'Unable to download report , please try again later. If issue persists, please contact Project Administrator at ',
    errorDeleteRequirement: 'Unable to delete, please try again later. If issue persists, please contact Project Administrator at ',
    errorMassUpdateRequirementSprint: 'Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ',
    errorLockRequirement: 'Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ',
    //Project error Handling
    errorCreateProject: 'Unable to create a new project, please try again later. If issue persists please contact KFS Support team at ',
    errorGetProjectSetupDetails: "Unable to fetch project Setup Details",
    errorSetProjectStatus: "Failed to set Project Status",
    errorGetProjectDetails: 'Unable to get project details ,  please try again later. If issue persists, please contact Project Administrator at ',
    errorGetAllSolutionScopes: 'Unable to get All Solution Scopes ,  please try again later. If issue persists, please contact Project Administrator at ',
    errorUpdateProject: "Unable to save changes ,  please try again later. If issue persists, please contact Project Administrator at ",
    errorUpdateRule: "Unable to perform the required action, please try again later . If issue persists, please contact Project Administrator at ",
    errorGetAllProjects: "Unable to get Project list , please try again later. If issue persists , please contact Project Administrator at ",
    errorGetUserProjects: "Unable to get Project list , please try again later. If issue persists , please contact Project Administrator at ",
    errorSaveBOTSDetails: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorSaveBODSDetails: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorSaveSharePointDetails: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorSaveOutlookDetails: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorUpdateTargetSystemDetails: "Unable to save changes ,  please try again later. If issue persists, please contact Project Administrator at ",
    errorSaveTargetSystemDetails: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorsaveALMInformation: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorSaveIP5Details: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorCreateSprint: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorDeleteSprint: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorUpdateSprint: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorCreateSources: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorUpdateSources: "Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ",
    errorUpdateProjectStatus: "Unable to save changes ,  please try again later. If issue persists, please contact Project Administrator at ",
    errorRolePermission1: "Access Restricted . Please contact Project Administrator at ",
    errorRolePermission2: " in case access is required",
    errorGetProjectRules: "Unable to perform the required action, please try again later . If issue persists, please contact Project Administrator at ",
    errorPingSourceSystem: "Unable to verify connection set up, please recheck Target system details",
    errorPingTargetSystem: "Unable to verify connection set up, please recheck Target system details",
    errorPingIP5System: "Unable to verify connection set up, please recheck Target system details",
    errorPingALMSystem: "Unable to verify connection set up, please recheck Target system details",
    errorPingBODSSystem: "Unable to verify connection set up, please recheck Target system details",
    errorPingBOTSSystem: "Unable to verify connection set up, please recheck Target system details",
    errorMassStatusUpdate: "Unable to save changes ,  please try again later. If issue persists, please contact Project Administrator at ",
    errorMigrateRequirements: "Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ",
    errorMigrateRicefw: "Failed to Migrate RICEFFW",
    errorGetRicefwList: "Unable to load Project RICEFW, please try again later. If issue persists , please contact Project Administrator at ",
    errorDownloadRiceFW: "Unable to download report ,  please try again later. If issue persists, please contact Project Administrator at ",
    errorCeateOrUpdateRicefw: "Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ",
    errorDeleteRicefw: "Failed to delete RICEFW",
    errorSplitRicefw: "Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ",
    errorMergeRicfew: "Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ",
    errorDuplicateRicefw: "Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ",
    errorDownloadUsers: "Unable to download report , please try again later. If issue persists, please contact Project Administrator at ",
    errorDeleteRicefFromReq: "Unable to delete, please try again later. If issue persists , please contact Project Administrator at ",
    errorGetVersions: "Unable to get Run Versions, please try again later. If issue persists , please contact Project Administrator at ",
    errorAbortJob: "Unable to perform the required action, please try again. If issue persists , please contact Project Administrator at ",
    errorSaveAnalytics: "Unable to save Analytics",
    errorDeleteAnalytics: "Unable to Delete Analytics",
    errorGetProjectModules: "Unable to get Project Modules",
    errorSaveProjectModules: "Unable to save Project Modules",
    errorGetAttributes: "Unable to fetch Project Attributes",
    errorSaveAttributes: "Unable to save Project Attributes",
    errorValidation: "Unable to validate the attribute",
    successAddingAttribute : "New item successfully added, Click Save to Proceed",
    errorAddingAttribute: "This item already exists. Please add a new item",
    errorAddEmail : "Unable to add Email",
    errorUpdateToggle: "Failed to save Toggle Rules",
    errorCreateorUpdateAhiConfig : "Unable to update AHI Config URL",
    errorDocSetting : "Unable to update Qa Tool Documentation",
    //Workshop error handling
    errorGetWorkshopDetails: 'Unable to get Workshop Details, please try again later. If issue persists, please contact Project Administrator at ',
    errorCreateWorkshop: 'Unable to perform the required action,  please try again later. If issue persists, please contact Project Administrator at ',
    errorGetWorkshopList: 'Unable to display Project Workshops , please try again later. If issue persists, please contact Project Administrator at ',
    errorEditWorkshop: 'Unable to save changes , please try again later. If issue persists, please contact Project Administrator at ',
    errorDeleteWorkshop: 'Unable to delete, please try again later. If issue persists, please contact Project Administrator at ',
    errorWorkshopNotes: 'Unable to perform the required action, please try again later. If issue persists, please contact Project Administrator at ',
    errorDeleteWorkshopNote: 'Unable to perform delete Workshop notes,  please try again later. If issue persists, please contact Project Administrator at ',
    errorSaveWorkshopNote: 'Unable to save changes,  please try again later. If issue persists, please contact Project Administrator at ',
    errorPreReadEmail: 'Failed to get PreRead Email',
    errorResultTracker: 'Failed to fetch Result Tracker',
    errorPrePread: 'Pre Read email template not available. Please contact Project Administrator at ',
    errorWorkshopReserveTime: 'Unable to perform the required action,  please try again later. If issue persists, please contact Project Administrator at ',
    errorWorkshopMailStatus: 'Unable to perform the required action,  please try again later. If issue persists, please contact Project Administrator at ',
    errorWorkshopGenerateDeck: 'Unable to perform the required action,  please try again later. If issue persists, please contact Project Administrator at ',
    errorDownloadWorkshopPlan: 'Unable to perform the required action,  please try again later. If issue persists, please contact Project Administrator at ',
    errorDownloadWorkshopNotes: 'Unable to download workshop notes,please try again later. If issue persists, please contact Project Administrator at ',
    //Auth error handling
    errorLogin: 'Failed to login',
    errorCreatePassword: 'Failed to create password',
    errorGenerateToken: 'Failed to generate token',
    errorGetRoleType: 'Failed to get Role type',
    //xref error handling
    errorGetXrefData: "Failed to get xref Details",
    errorUpdateXrefData: "Failed to update xref Details",
    errorDownloadXrefData: "Failed to download xref Data",
    errorExcelFile: "Failed to upload XREF details due to updates made on fields other than override value",
    //touchless error handling
    errorGetAHIStatus: "Failed to get AHI Status",
    errorGetAWSS3PreSignedURL: "Failed to get AWSS3 Pre Signed URL",
    errorExecuteHarmonizeJob: "Unable to perform the required action, please try again. If issue persists , please contact Project Administrator at ",
    errorExecuteImplementJob: "Unable to perform the required action, please try again. If issue persists , please contact Project Administrator at ",
    errorExecuteExtractJob: "Unable to perform the required action, please try again. If issue persists , please contact Project Administrator at ",
    //User error handling
    errorSaveUser: 'Unable to save changes , please try again later. If issue persists , please contact Project Administrator at ',
    errorUserRolePermission: 'Failed to get User Role Permission',
    errorDeleteUser: 'Unable to delete, please try again later. If issue persists, please contact Project Administrator at ',
    errorRestrictUser:'Unable to restrict, please try again later. If issue persists, please contact Project Administrator at ',
    //ClientPrint error handling
    errorCreateorUpdateNodes: 'Unable to save changes, please try again later. If issue persists, please contact Project Administrator at ',
    errorDeleteL4: 'Failed to Delete L4',
    errorSaveRicefwData: 'Failed to save Ricefw Data',
    errorSaveReqData: 'Failed to save Requirements Data',
    errorgetTcodes: 'Failed to get Tcodes',
    errorgetBusinessRoles: 'Failed to get Business Roles',
    errorGetRicefwandReq: 'Failed to get Ricefw and Requirements',
    errorSaveChangeLogData: 'Failed to get response from Change Log Data',
    errorRemoveL4FromReq: 'Failed to remove L4 from Requirement',
    errorDownloadReport: 'Failed to download Report',
    errorProcessScopeAPI: 'Industry Sector Print not available in the Library . Please contact Project Administrator at ',
    errorDeleteNode: 'Failed to delete Client Print Node',
    errorRoleDeletionL1: 'Process Lead or Process Team Member cannot delete L1 data',
    errorRoleDeletionL2:'Process Team Member cannot delete L2 data',
    errorOutofScopeDeletion: 'Out of scope nodes can only be deleted',
    saveDocSettings : "Successfully saved AHI Config details",
    saveDocumentationsetting : "Successfully saved QA Tool Documentation",
    errorNoProjectAccess: 'Access Restricted . Please contact System Administrator in case access is required',
    errorNoProjectAccessSelfRegistraion: 'Sorry access restricted! Please use register button to get access to projects',
    registrationFailed :'User Failed to get Register',
    registrationSuccess : 'Thank you for registering. You should be able to access the project, once you receive the email confirmation',
    defaultProjectAccess:'Yayy! You are ready to explore Smart Offering',
    alreadyUser: 'Already registered for selected project. Please try with different project',
    saveWorkshopInvite: 'Successfully Saved Workshop Invite',
    errorWorkshopInvite: 'Failed to Save Workshop Invite',
    onBusinessRulesFileUpload : 'Successfully uploaded Business Rules file',
    onIP5FileUpload : 'Successfully uploaded IP5 file',
    errorOnUpload: 'Please select Industry, Industry Sector ,Solution Type and Mapping Entity before uploading template',
    noFilteredResults: 'No results found for the applied filters',
    errorAryaIntegration: 'Unable to perform the required action,  please try again later. If issue persists, please contact Project Administrator at ',
    deleteClientPrintNode: 'Selected node(s) has been successfully deleted',
    sentAryaRequest: 'Request sent to Arya to fetch details for selected RICEFWs',
    updateArya: 'Selected Arya RICEFWs have been saved successfully',
    //Super Admin error handling
    errorUploadSuperAdmin: 'Failed to Upload Template',
    errorGetProjectList: 'Failed to fetch Project List',
    errorGenerateProjectsTemplate: 'Failed to Download Project Template',
    errorDownloadTemplate: 'Failed to Download Template',
    errorViewConfig: "Failed to Load Config",
    errorConfigDoc: 'Config Doc is not available for this node!',
    doesNotExist : 'Looks like the nodes in this variant are not supported',
    error_downloadSystem : 'Error: Selected config nodes are not available in source system.',
    errorCheckMessage : 'Save the nodes first before you check validity of the nodes',
    errorUploadQuestionnaire: 'Failed to Upload column header not matching',
    errorOnUploadQues: 'Please select RICEFW Object Type before uploading template',
    projReqUpload: 'Successfully uploaded project requirements',
    errorDownloadProjReq: 'Failed to Download Project Requirements data',
    errorDownloadMasterRulesData: 'Failed to Download Master Rules data',
    successDocUpload: 'We have initiated the processing of your documents. Please come back after sometime to view the results',
    savevariantName : 'Variant and sequence nodes were saved successfully',
    savevariantNameToECC : 'Variant and sequence nodes were saved successfully to ECC system',
    duplicateVariant : 'Your have entered duplicate Variant Name, please enter again',
    deleteVariantName : 'Variant deleted successfully',
    errorDeleteVariantName : 'Could not delete the variant',
    statusUpdateReq: 'Updated Status for selected fit requirement(s)',
    assigneeUpdateReq: 'Updated Assignee for selected fit requirement(s)',
    deleteRequirement: 'Successfully deleted selected requirement(s)',
    shareFeedback: "Your feedback has been sent to the product team",
    fileDownload: "Your file is ready to be downloaded",
    noReqsAvailable: "No Fit requirements/user stories are available to show up at this moment",
    showSupportConfig: "Some actions will not work as you haven't enabled SAP backend connectivity. Please reach out to our Support team smartofferingsupport@deloitte.com",
    downloadFiles: "Please wait for the files to be downloaded",
    downloadError : "Error in processing, download failed",
    waitFilesToDownload: "Please wait for the file(s) to download",
    errorSAP_ID: "SAP ID is not configured. Please reach out to your Project Admin(s)",
    errorInSyncWithConfigNodes : "Error while Syncing Variant Name & Config Nodes",
    syncToECC_S4: "Variant Name & Config Nodes Successfully Synced To ECC & S4 system",
    taskInProgress:"Document generation is in progress",
    errorInSyncWithSystem : "Variant Name and Config Nodes are not synced Successfully",
    SAPContextInfo:"Please provide input in context with SAP",
    errorFromFiscalYearRequired:"From year is required",
    errorToFiscalYearRequired:"To year is required",
    errorWithValidYear:"Enter a valid year with format (YYYY)",
    errorWithFromYearLessthanToYear:"From year should be less than To Year",
    AutoIntake: "Auto Intake",
    failedDocDownload: "Failed to download document",
    project_name: "project_name",
    Extract: "Extract",
    Harmonize: "Harmonize",
    Implement: "Implement",
    AbortInProgress: "AbortInProgress",
    Started: "Started",
    canCreate: "canCreate",
    canUpdate: "canUpdate",
    AbortConfirmationMessage: 'Are you sure you want to Abort the Orchestrate Process?',
    ResetExtractConfirmationMessage: 'Are you sure you want to Reset Auto-intake Job?',
    ResetHarmonizeConfirmationMessage: 'Are you sure you want to Reset Harmonize Job?',
    ResetImplementConfirmationMessage: 'Are you sure you want to Reset Implement Job?',
    ResetEntireRunConfirmationMessage: 'Would you like to reset complete run ID and re-start?',
    UnsavedChangesDiscardMessage: "There are unsaved changes. Are you sure you want to leave without saving?",
    errorTrackingExclusion: "Unable to save project tracking details ,  please try again later.",
    updateProjectTracking:  'Successfully updated Project Tracking Exclusion',
    fileErrorMessage : 'File is failed to processed',
    downloadSuccess : 'Download of file is successfull',
};
