import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SnackBarComponent} from './components/snackbar.component';
import {SnackService} from './services/snack.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { CustomToolTipComponent } from './components/custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from './directives/custom-tooltip.directive';
import { ClickOutsideDirective } from './directives/clickoutside.directive';
import { SelectCheckPipe } from './pipes/select.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { ArrFilter } from './pipes/filterArr';
import { SprintPipe } from './pipes/sprint.pipe';
import { FilterAhiPipe } from './pipes/filter-ahi.pipe';
import { HeaderComponent } from '../header/header.component';
import { MatSelectModule } from '@angular/material/select';
import { InitialsFilter } from './pipes/initials.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { JoinByPipe } from './pipes/join.pipe';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { ProposalComponent } from './components/proposal/proposal.component';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonPopup } from './components/common-popup/common-popup.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { DescriptionComponent } from './components/description/description.component';
import { MatCardModule } from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import { ProjectFilter } from './pipes/filterProjects.pipe';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { GenAIPopup } from './components/gen-ai-popup/gen-ai-popup.component';
import { SpecificationGenAIComponent } from './components/app-specification-gen-ai/app-specification-gen-ai.component';
import { MatSelectSearchModule } from 'mat-select-search';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';


@NgModule({
  declarations: [
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ClickOutsideDirective,
    SelectCheckPipe,
    SearchPipe,
    ArrFilter,
    SprintPipe,
    FilterAhiPipe,
    SnackBarComponent,
    HeaderComponent,
    JoinByPipe,
    InitialsFilter,
    QuestionnaireComponent,
    ProposalComponent,
    CommonPopup,
    AlertDialogComponent,
    ProjectFilter,
    DescriptionComponent,
    GenAIPopup,
    SpecificationGenAIComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatSnackBarModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatGridListModule,
    MatProgressBarModule,
    MatChipsModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSortModule,
    MatDialogModule,
    MatCardModule,
    MatStepperModule,
    MatMenuModule,
    MatListModule,
    MatSliderModule,
    MatSelectSearchModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SnackBarComponent,
    ToolTipRendererDirective,
    ClickOutsideDirective,
    SelectCheckPipe,
    SearchPipe,
    ArrFilter,
    FilterAhiPipe,
    SprintPipe,
    FilterAhiPipe,
    HeaderComponent,
    JoinByPipe,
    InitialsFilter,
    QuestionnaireComponent,
    ProposalComponent,
    ProjectFilter,
    DescriptionComponent,
  ],
  providers: [
    SnackService,
  ]
})
export class SharedModule { }
